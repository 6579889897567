@import '../../styles/variables.scss';

.payment {
  &__top-field {
    display: flex;
    align-items: center;
    height: 60px;
    padding: 20px 55px;
    margin: 18px 26px 0 18px;
    background-color: use-color('light-dim');
    border-radius: 5px;
    font-size: 16px;
  }

  &__name {
    font-size: 24px;
  }

  &__light {
    display: inline-block;
    width: 5px;
    height: 5px;
    margin-right: 20px;
    background-color: use-color('green');
  }

  &__search-input {
    width: 250px;
    height: 40px;
    padding: 12px;
    margin-left: 52px;
    background: use-color('white');
    border: 1px solid use-color('dark');
    border-radius: 5px;
  }

  &__search-button {
    width: 150px;
    margin-left: 10px;
    border-radius: 5px;
    font-weight: 300;
  }

  &__form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 430px;
    height: 350px;
    padding: 0;
    margin: 60px 100px;
  }

  &__input-layout {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__input {
    width: 300px;
    height: 40px;
    padding: 12px;
    background: use-color('white');
    border: 1px solid use-color('dark');
    border-radius: 5px;
  }

  &__number {
    -moz-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      margin: 0;
      -webkit-appearance: none;
    }
  }

  &__date {
    margin-right: -48px;
  }

  &__day-layout,
  &__month-layout {
    position: relative;
    display: inline-block;
  }

  &__select-date {
    display: inline-block;
    width: 50px;
    height: 40px;
    padding: 12px 8px;
    margin-right: 8px;
    background-color: use-color('white');
    border: 1px solid use-color('dark');
    border-radius: 5px;
    color: use-color('dark');
    text-align-last: center;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    &::-ms-expand {
      display: none;
    }
  }

  &__increase-date,
  &__decrease-date {
    position: absolute;
    width: 8px;
    height: 5px;
    background-image: url('../../assets/icons/date-arrow.svg');
    background-repeat: no-repeat;
  }

  &__increase-date {
    top: -10px;
    left: 20px;
  }

  &__decrease-date {
    bottom: -10px;
    left: 20px;
    transform: rotate(180deg);
  }

  &__date-label {
    margin-right: 14px;
    font-size: 18px;
  }

  &__year {
    display: inline-block;
    width: 80px;
    height: 40px;
    margin-right: 8px;
    background: use-color('white');
    border: 1px solid use-color('dark');
    border-radius: 5px;
    text-align: center;
    -moz-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      margin: 0;
      -webkit-appearance: none;
    }
  }

  &__textarea-layout {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  &__textarea-label {
    margin-top: 10px;
  }

  &__textarea {
    width: 300px;
    height: 110px;
    padding: 12px;
    background: use-color('white');
    border: 1px solid use-color('dark');
    border-radius: 5px;
    color: use-color('dark-light');
    resize: none;
  }

  &__submit-form {
    width: 150px;
    margin-left: 280px;
    border-radius: 5px;
  }

  &__table {
    margin: 50px 26px 0 18px;

    .table__head {
      tr {
        th {
          padding-left: 20px;
          text-align: left;

          &:nth-child(1) {
            min-width: 70px;
          }

          &:nth-child(2) {
            min-width: 180px;
          }

          &:nth-child(3) {
            min-width: 360px;
          }

          &:nth-child(4),
          &:nth-child(5),
          &:nth-child(6) {
            min-width: 200px;
          }

          &:nth-child(7) {
            min-width: 320px;
          }
        }
      }
    }

    .table__body {
      tr {
        td {
          padding: 18px 0 18px 20px;
          text-align: start;
        }

        &:nth-child(even) {
          background-color: use-color('light-dim');
        }
      }
    }
  }
}
