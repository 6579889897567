@import '../../styles/variables.scss';

.container {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  flex-flow: row;
  flex-direction: row;

  &__boxed {
    flex-flow: column;
    flex-direction: column;
    align-self: center;
    width: 80%;
    max-width: 600px;
    margin: 0 auto;
    background-color: use-color('white');
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.25);
    text-align: center;
    transition: width, height 0.5s linear;

    @include tablet {
      @include landscape {
        align-self: auto;
        width: 100%;
        max-width: inherit;
        box-shadow: none;
      }
    }
    @include mobile {
      align-self: auto;
      width: 100%;
      max-width: inherit;
      box-shadow: none;
    }

    &-rounded {
      border-radius: 2em;
      @include mobile {
        border-radius: 0;
      }
    }

    &-flat {
      box-shadow: none;
    }

    &__title {
      font-size: 1.25em;
      font-weight: 500;
    }
  }

  &__title {
    &-margin {
      margin-top: 18px;
    }
  }

  &__text {
    &-margin {
      margin-top: 1.3em;
    }
  }
}
