@import '../../styles/variables.scss';

.popup-fullscreen {
  &__shadow {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
    display: flex;
    width: 100%;
    height: calc(100% - 55px);
    background-color: rgba(71, 79, 100, 0.9);
  }

  &__container {
    min-width: 400px;
    margin: auto;
  }

  &__icon {
    width: 60px;
    height: 55px;
    margin: 0 auto 18px;
    background-image: url('../../assets/icons/video-project-icon.svg');
  }

  &__title {
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: 400;
    line-height: 21px;
    text-align: center;
    color: use-color('white');
  }

  &__input {
    width: 400px;
    height: 40px;
    padding: 10px;
    margin-bottom: 20px;
    background-color: use-color('white');
    border-radius: 10px;
    background-image: url('../../assets/images/loader.png');
    background-repeat: no-repeat;
    background-size: 30px;
    background-position-x: 360px;
    background-position-y: center;
  }

  &__create-button {
    display: block;
    width: 140px;
    height: 40px;
    margin: 0 auto;
    background-color: use-color('dark-green');
    border: 3px solid use-color('dark-green');
    border-radius: 4px;
    color: use-color('white');

    &:hover {
      border: 3px solid use-color('light-dim');
    }

    &:active {
      border: 3px solid rgba(0, 0, 0, 0.2);
    }
  }

  &__close-button {
    position: absolute;
    top: 1.8vw;
    right: 1.8vw;
    width: 36px;
    height: 36px;
    background-image: url('../../assets/icons/close-button-hollow.svg');
    transition: $main-transition;

    &:hover,
    &:active {
      background-image: url('../../assets/icons/close-button-hollow-hover.svg');
    }
  }

  &__error-container {
    width: 400px;
    height: 80px;
    padding: 10px 0;
    margin-top: 20px;
    background-color: use-color('red');
    border-radius: 10px;
    color: use-color('white');
  }

  &__error-title {
    width: 130px;
    height: 22px;
    margin-left: 139px;
    background-image: url('../../assets/icons/project-error-icon.svg');
    background-size: 20px 18px;
    background-repeat: no-repeat;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    color: use-color('white');
    background-position-x: left;
    background-position-y: center;
  }

  &__error-value {
    margin-top: 14px;
    text-align: center;
  }
}
