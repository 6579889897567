@import '../../styles/variables.scss';

.table {
  position: relative;
  width: 100%;
  font-size: 1.25em;

  @include tablet {
    font-size: 1.1em;
  }

  @include mobile {
    font-size: 1em;
  }

  &__wrapper {
    position: relative;
    max-height: 78vh;
    overflow: auto;
    border-radius: 0.5em;
    border-top: solid 4px use-color(gray-light);
    background-color: use-color(gray-light);
  }

  &__head {
    text-align: left;
    color: use-color('white');

    > tr {
      > th {
        position: sticky;
        top: 0;
        z-index: 100;
        font-size: 16px;
        padding: 0.45em 0.43em;
        vertical-align: middle;
        background-color: use-color('gray-light');
        transition: all 0.3s;
        cursor: default;

        &:not([data-accessor="checkbox"]) {
          min-width: 146px;
        }

        &:first-child:not([data-accessor="checkbox"]) {
          padding-left: 48px;
          min-width: 280px;
        }

        &:not(:last-child) {
          border-right: solid 2px use-color('white');
        }

        .table__head-sort {
          width: 12px;
          height: 21px;
          background-image: url('../../assets/icons/arrow-mixed.svg');
          background-repeat: no-repeat;
          background-position: center;
          background-size: 8px 14px;

          &:hover {
            background-image: url('../../assets/icons/sort-hover.svg');
            background-size: cover;
          }
        }

        &.no-arrow {
          .table__head-sort {
            display: none;
          }
        }

        &.table__head--asc {
          .table__head-sort {
            background-image: url('../../assets/icons/sort-ascending.svg');
            background-size: cover;
          }
        }

        &.table__head--desc {
          .table__head-sort {
            background-image: url('../../assets/icons/sort-descending.svg');
            background-size: cover;
          }
        }

        &[data-accessor='favorite'] {
          min-width: unset;

          .table__head-container {
            margin-left: 11.25px;
          }
        }
      }
    }
  }

  &__head-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__head-filters {
    display: flex;
    align-items: center;
    margin-left: 0.33em;
  }

  &__body {
    > tr {
      > td {
        padding: 0.5em 1em;
        vertical-align: middle;
        text-align: left;
        font-size: 16px;

        &:first-child {
          text-align: left;
        }

        .form__checkbox {
          transform: scale(0.75);
          transform-origin: center;

          &-mask {
            margin: 0;
          }

          label > span:not(:empty) {
            margin-left: unset;
          }
        }
      }

      background-color: use-color('white');

      &:nth-child(even) {
        background-color: use-color('light');
      }
    }
  }

  &__icon {
    display: inline-block;
    height: 22px;
    padding-right: 0.5em;
    vertical-align: middle;

    &-name {
      vertical-align: -5px;
    }

    &-condition {
      width: 10px;
      height: 10px;
      padding: 0;
      margin-right: 12px;
    }

    &__action {
      height: 26px;
      padding: 2px;
      margin-right: 10px;
      border-radius: 0.5em;
      transition: all 0.3s;

      &:hover,
      &:active {
        background-color: use-color('light-dim');
      }
    }

    &-pointer {
      cursor: pointer;
    }

    &-favorite {
      padding-right: 0;
    }
  }
}
