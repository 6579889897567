.invoice {
  &-actions,
  &-status {
    align-items: center;
    display: flex;
    column-gap: 6px;

    .button {
      width: unset;
      white-space: nowrap;
    }
  }

  &-actions {
    .button {
      height: 36px;
      line-height: 36px;
      padding: 0 8px;
      font-size: 15px;
    }
  }

  &-status {
    .button {
      height: 32px;
      line-height: 32px;
      padding: 0 4px;
      font-size: 14px;
    }
  }
}

#accountantInvoices {
  .page-plate__body {
    padding: 10px 0;
  }
}
