@import '../../styles/variables.scss';

.media {
  &__list {
    display: grid;
    height: 400px;
    margin-top: 10px;
    overflow-y: auto;
    grid-template-columns: repeat(auto-fit, 140px);
    grid-auto-rows: 190px;
    gap: 20px;

    &__item {
      &-action {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50%;
        padding: 0.25em;
        border-radius: 2em;
        transition: all 0.3s;

        &:hover,
        &:active {
          width: 100%;
          border-radius: 0.25em;
          background-color: use-color('white');
        }
      }
    }
  }
}

.media-list {
  &__item {
    position: relative;
    align-items: center;
    width: 140px;
    height: 190px;
    border: solid 2px #dbe0f2;
    border-radius: 5px;
    background-color: #dbe0f2;
    text-align: center;
    font-size: 12px;
    transition: all 0.3s;

    &:hover {
      border-color: use-color('green');
    }

    &-filename {
      display: inline-block;
      width: 120px;
      margin-top: 5px;
      margin-bottom: 7px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &-textarea {
      display: inline-block;
      width: 100px;
      margin-top: 1px;
      margin-bottom: 1px;
      background-color: use-color('white');
      border: solid 2px use-color('green');
      border-radius: 5px;
      text-align: center;
      resize: none;
      scrollbar-width: none;
      -ms-overflow-style: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    &-preview {
      position: relative;
      width: 100px; // TODO: Fix later by size
      height: 62px; // TODO: Fix later by size
      margin-top: 10px;
      overflow: hidden;
      border-radius: 3px;

      &_image {
        height: 62px;
        object-fit: cover;
      }

      &_ext {
        position: absolute;
        top: 0;
        right: 0;
        padding: 0.35em;
        border-radius: 3px;
        background-color: use-color('green');
        color: use-color('white');
        font-size: 0.75em;
        font-weight: 600;
        text-align: center;
        text-transform: uppercase;
      }

      &_checkbox {
        align-self: flex-start;
        margin-top: -16px;
        margin-left: 5px;
        transform: scale(0.75);
      }
    }

    &-status {
      display: flex;
      align-items: center;
      text-transform: lowercase;

      img {
        height: 0.75em;
        margin-right: 0.25em;
      }
    }

    &-file-info {
      width: 130px;
      padding: 3px;
      margin: 5px 0;
      overflow: hidden;
      border: solid 1px use-color(dark);
      background-color: use-color('white');
      text-overflow: ellipsis;
      white-space: nowrap;

      &_duration,
      &_size {
        padding: 0 0.5em;
      }

      &_duration {
        border-right: solid 1px use-color(dark);
      }
    }

    &-menu {
      position: absolute;
      z-index: 1;
      top: 100px;
      left: 100px;
      width: 227px;
      height: 74px;
      background: use-color('light');
      border-radius: 5px;
    }

    &-action {
      display: flex;
      align-items: center;
      height: 37px;
      padding-left: 10px;
      font-size: 14px;
      cursor: pointer;

      &:hover,
      &:active {
        background-color: use-color('light-dim');
      }
    }

    &__button {
      &_add,
      &_remove {
        width: 1.75em;
        background-position: center;
        background-repeat: no-repeat;
        transition: all 0.3s;
      }

      &_remove {
        background-image: url(../../assets/icons/added-hover.svg);
        transform: rotate(-45deg);

        &:disabled {
          background-image: url(../../assets/icons/added.svg);
          opacity: 0.5;
          cursor: default;
        }

        &:not(:disabled):hover,
        &:not(:disabled):active {
          background: url(../../assets/icons/added.svg) no-repeat center;
        }
      }

      &_add {
        background: url(../../assets/icons/added.svg) no-repeat center;

        &:not(:disabled):hover,
        &:not(:disabled):active {
          background-image: url(../../assets/icons/added-hover.svg);
        }
      }
    }
  }
}
