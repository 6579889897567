@import './variables.scss';

/* stylelint-disable declaration-no-important */
.react-date-picker,
.react-daterange-picker {
  --border-radius: 4px;

  &__wrapper {
    display: block;
    width: 140px;
    margin: 0 27px 10px auto;
    border: 0;
    border-radius: var(--border-radius);
  }

  &__inputGroup {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 140px;
    height: 20px;
    margin: 10px 0 0;
    border: 1px solid use-color('gray-light');
    border-radius: var(--border-radius);
    font-size: 12px;
    line-height: 14px;
    color: use-color('dark');
    cursor: pointer;

    &__input {
      color: currentColor;
      cursor: pointer;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        margin: 0;
        -webkit-appearance: none;
      }
    }
  }

  &__range-divider {
    display: none;
  }

  &__calendar {
    position: static !important;
    height: auto !important;
  }
}

.react-calendar {
  width: 210px;
  margin: 15px auto;

  &__navigation {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    font-size: 12px;
    line-height: 14px;
  }

  &__navigation__label {
    color: use-color('dark');
  }

  &__month-view__weekdays {
    justify-content: space-between;
    width: 200px;
    margin: 0 auto;

    &__weekday {
      flex-basis: 16px !important;

      abbr {
        font-size: 12px;
        text-decoration: none;
        color: use-color('dark');
      }
    }
  }

  &__month-view__days__day {
    flex-basis: 20px !important;
    height: 20px;
    margin: 5px;
    font-size: 12px;
    line-height: 14px;
    color: use-color('dark');

    &:hover {
      background-color: use-color('light-dim');
      border-radius: 2px;
    }
  }

  &__year-view__months {
    display: flex;
    justify-content: center;

    &__month {
      flex-basis: 60px !important;
      font-size: 12px;
      line-height: 24px;
      color: use-color('dark');

      &:hover {
        background-color: use-color('light-dim');
        border-radius: 2px;
      }

      &:active {
        background-color: use-color('dark-green');
        border-radius: 2px;
        color: use-color('white');
      }
    }
  }

  &__decade-view__years {
    display: flex;
    justify-content: center;

    &__year {
      flex-basis: 40px !important;
      font-size: 12px;
      line-height: 24px;
      color: use-color('dark');

      &:hover {
        background-color: use-color('light-dim');
        border-radius: 2px;
      }

      &:active {
        background-color: use-color('dark-green');
        border-radius: 2px;
        color: use-color('white');
      }
    }
  }

  &__tile--hover {
    border: 1px solid use-color('dark-green');
    background-color: unset !important;
    border-radius: 2px;
  }

  &__tile--active {
    background: none;
    border: 1px solid use-color('dark-green');
    border-radius: 2px;

    &:hover {
      background-color: use-color('dark-green');
      border-radius: 2px;
      color: use-color('white');
    }
  }

  &__tile--rangeStart,
  &__tile--rangeEnd {
    background-color: use-color('dark-green') !important;
    border-radius: 2px;
    color: use-color('white');
  }
}

/* stylelint-enable declaration-no-important */
