@import '../../styles/variables.scss';

.add__edit {
  &__wrapper {
    display: flex;
    flex-direction: column;
    padding: 1.5em;
    margin-bottom: 2em;
    border-radius: 1em;
    background-color: use-color('white');
  }

  &__header {
    align-items: center;
    padding: 0.5em 0;
    margin-bottom: 2em;
    border-bottom: dashed 1px use-color(dark-light);
    color: use-color(dark);

    h2,
    h3 {
      padding: 0.25em 0;
    }
  }

  &__title {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: use-color('gray');
  }

  &__icon {
    height: 1.4em;
    padding: 0 0.5em;
  }

  &__field {
    align-items: center;
    justify-content: center;
    margin-bottom: 2em;

    &__input {
      &_wrapper {
        margin-left: auto;
      }
    }
  }

  &__label {
    font-size: 16px;
  }

  &__hr {
    flex-grow: 1;
    height: 0;
    margin: 0 1em;
    border-bottom: dashed 1px use-color('gray');
  }

  &__form {
    padding: 0;

    &__big_field {
      margin: 2em auto;

      .big-input__form {
        &_hint {
          display: block;
          padding: 0.25em;
          font-size: 18px;
          line-height: 21px;
          color: use-color('green');
        }
      }
    }

    &__field {
      .form {
        &__input {
          width: 300px;
          height: 40px;
          border: solid 1px use-color('gray');
          border-radius: 0.3em;
          text-indent: 0.5em;
          line-height: 19px;
          font-weight: 300;
          font-size: 16px;

          &-category {
            font-weight: 300;
          }

          &_select {
            height: 2.3em;
            padding: 0;
            padding-right: 2.5em;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          + label {
            top: 0;
            left: 0;
            padding: 1em;
            font-size: 14px;

            @include mobile {
              font-size: 0.9em;
            }
          }

          &:focus {
            border-color: use-color('green');
          }

          &:focus + label,
          &:not(:focus):not(:placeholder-shown) + label {
            top: 0;
            left: 0;
            font-size: 0.85em;
            transform: translate3d(0, -80%, 0);

            @include mobile {
              font-size: 0.7em;
            }
          }

          &:not(:focus):not(:placeholder-shown) + label {
            color: use-color('gray');
          }
        }
      }
    }
  }
}

.content {
  width: 100%;
  min-width: 724px;
  height: calc(100% - $header-height);
  overflow: auto;

  &--with-padding {
    padding: 2em 1.5em 0 2em;
  }

  &--authentication {
    min-width: unset;
  }

  &__wrapper {
    position: relative;
    flex-grow: 1;
  }
}
