@import './variables.scss';

@keyframes toast-download-pending {
  0% {
    width: 0%;
  }

  100% {
    width: 100%;
  }
}

.Toastify {
  &__toast-container--top-right {
    top: 45px;
    right: 150px;
  }

  &__toast-message {
    align-items: center;
    width: 400px;
    min-height: 50px;
    height: 50px;
    padding: 5px 20px 5px 60px;
    background-repeat: no-repeat;
    background-position: left;
    background-color: use-color('white');
    border: 0.5px solid #cdd1df;
    border-radius: 5px;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
    color: use-color('dark');

    &.Toastify__toast {
      &--info {
        background-image: url('../assets/icons/info-toast.svg');
      }

      &--success {
        background-image: url('../assets/icons/success-toast.svg');
      }

      &--warning {
        background-image: url('../assets/icons/warning-toast.svg');
      }

      &--error {
        background-image: url('../assets/icons/error-toast.svg');
      }
    }
  }

  &__toast-body {
    padding: 0;
  }

  &__toast-download-container {
    overflow: visible;
    width: 250px;
    min-height: 50px;
    height: 50px;
    padding: 0;
    background-color: use-color('white');
    border: 0.5px solid #cdd1df;
    border-radius: 5px;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
    color: use-color('dark');
  }
}

.toast {
  &__text {
    width: 300px;
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
  }

  &__title {
    font-weight: 500;
  }

  &__description {
    // stylelint-disable value-no-vendor-prefix, property-no-vendor-prefix
    display: -webkit-box;
    margin-top: 3px;
    overflow: hidden;
    font-weight: 300;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    // stylelint-enable value-no-vendor-prefix, property-no-vendor-prefix
  }

  &__close-button {
    width: 11px;
    height: 11px;
    background-image: url('../assets/icons/toast-close-button.svg');
  }
}

.toast-download {
  &__body {
    display: flex;
    align-items: center;
    font-family: 'Roboto', sans-serif;
    font-size: 9px;
  }

  &__icon {
    width: 50px;
    height: 50px;
    background-color: use-color('green');
    background-image: url('../assets/icons/download-progress-icon.svg');
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: 8px;

    &--pause {
      background-color: use-color('dark');
      background-image: url('../assets/icons/download-pause-icon.svg');
    }

    &--error {
      background-color: use-color('red');
      background-image: url('../assets/icons/download-error-icon.svg');
    }

    &--success {
      background-image: url('../assets/icons/download-success-icon.svg');
    }
  }

  &__percent {
    display: flex;
    justify-content: center;
    margin-top: 35px;
    font-weight: 500;
    font-size: 10px;
    color: use-color('white');
  }

  &__content {
    position: relative;
    width: 200px;
  }

  &__info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
  }

  &__title {
    display: block;
    font-size: 1.1em;
    font-weight: 500;
  }

  &__description {
    display: block;
    margin: 0.25em 0;
    font-weight: 200;
    color: inherit;
  }

  &__continue {
    width: 5px;
    height: 8px;
    margin-right: 14px;
    background-image: url('../assets/icons/download-continue.svg');

    &--error {
      background-image: url('../assets/icons/download-continue-dark.svg');
    }

    &--success {
      background-image: url('../assets/icons/download-continue-disabled.svg');
    }
  }

  &__pause {
    width: 6px;
    height: 8px;
    margin-right: 14px;
    background-image: url('../assets/icons/download-pause.svg');

    &--pause {
      background-image: url('../assets/icons/download-pause-dark.svg');
    }

    &--success {
      background-image: url('../assets/icons/download-pause-disabled.svg');
    }
  }

  &__button-wrapper {
    position: relative;
    display: inline-block;
  }

  &__close {
    width: 8px;
    height: 8px;
    background-image: url('../assets/icons/download-close.svg');

    &--pause {
      background-image: url('../assets/icons/download-close-dark.svg');
    }

    &--success {
      background-image: url('../assets/icons/download-close-disabled.svg');
    }
  }

  &__confirm-delete {
    position: absolute;
    z-index: 1;
    bottom: 5px;
    right: -56px;
    width: 120px;
    height: 60px;
    background-image: url('../assets/icons/download-confirm-delete.png');
    background-size: 120px 60px;
    background-repeat: no-repeat;
  }

  &__confirm-message {
    display: flex;
    justify-content: center;
    margin: 15px 0 0;
    font-weight: 200;
    font-size: 9px;
    line-height: 11px;
  }

  &__confirm-buttons {
    display: flex;
    margin: 3px 15px;
    font-weight: 200;
    font-size: 9px;
  }

  &__confirm-cancel {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 15px;
    background-color: use-color('green');
    color: use-color('white');
  }

  &__confirm-ok {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 15px;
    margin-left: 10px;
    border: 1px solid use-color('gray-light');
    color: use-color('dark');
  }

  &__progress-bar {
    height: 4px;
    margin-top: 2px;
    background-color: use-color('light-dim');
  }

  &__progress {
    --width-percent: 0%;
    width: var(--width-percent);
    height: 4px;
    background-color: use-color('green');

    &--paused {
      background-color: use-color('dark');
    }

    &--success {
      width: 100%;
    }
  }

  &__filesize {
    padding: 0 10px;
    margin-top: 5px;
    font-weight: 200;
  }
}
