@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);

  @if $index {
    // prettier-ignore
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(
      str-slice($string, $index + str-length($search)),
      $search,
      $replace
    );
  }

  @return $string;
}

@mixin font-face($font-family, $file-path, $weight: normal, $style: normal) {
  @font-face {
    font-family: $font-family;
    font-style: $style;
    font-weight: $weight;
    src: url('../assets/fonts/#{$file-path}.eot');
    // prettier-ignore
    src:
      local('#{$font-family}'),
      local('#{$file-path}'),
      url('../assets/fonts/#{$file-path}.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/#{$file-path}.woff') format('woff'),
      url('../assets/fonts/#{$file-path}.ttf') format('truetype');
  }
}

@mixin roboto {
  font-family: 'Roboto', sans-serif;
}
