@import '../../styles/variables.scss';

/* stylelint-disable no-descending-specificity */
.editor-list {
  &__title {
    margin: 20px 0;
  }

  &__table {
    .table__head {
      tr {
        th {
          &:nth-child(1),
          &:nth-child(2) {
            min-width: 210px;
          }

          &:nth-child(3),
          &:nth-child(4),
          &:nth-child(5),
          &:nth-child(6),
          &:nth-child(7) {
            min-width: 120px;
          }
        }
      }
    }
  }
}

.editor {
  &__layout {
    min-width: 1100px;
    display: grid;
    grid-template-columns: auto min(69%, 800px);
    grid-template-areas:
      'library player'
      'timeline timeline';

    @media screen and (min-width: 1600px) {
      grid-template-columns: auto min(69%, 1000px);
    }
  }
}

.editor-content {
  border-right: solid 1px #cdd1df;
  grid-area: library;

  &__upload {
    flex-shrink: 0;
    width: 80px;
    height: 80px;
    background-color: use-color('dark-green');
    background-image: url('../../assets/icons/upload.svg');
    background-position: center;
    background-repeat: no-repeat;
  }

  &__item {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    line-height: 21px;
    font-size: 18px;
    color: use-color('white');
    text-transform: lowercase;
    transition: all 0.3s;
    cursor: pointer;

    &:hover {
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        width: 0;
        height: 0;
        border-left: 22px solid transparent;
        border-right: 22px solid transparent;
        border-bottom: 14px solid use-color('light');
      }
    }

    &:active {
      &::before {
        content: '';
        position: absolute;
        bottom: 0;
        width: 0;
        height: 0;
        border-left: 26px solid transparent;
        border-right: 26px solid transparent;
        border-bottom: 17px solid use-color('dark-green');
      }

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        width: 0;
        height: 0;
        border-left: 22px solid transparent;
        border-right: 22px solid transparent;
        border-bottom: 14px solid use-color('light');
      }
    }

    &--active {
      &::before {
        content: '';
        position: absolute;
        bottom: 0;
        width: 0;
        height: 0;
        border-left: 26px solid transparent;
        border-right: 26px solid transparent;
        border-bottom: 17px solid use-color('dark-green');
      }

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        width: 0;
        height: 0;
        border-left: 22px solid transparent;
        border-right: 22px solid transparent;
        border-bottom: 14px solid use-color('light');
      }
    }
  }

  &__bar {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 80px;
    background-color: use-color('green');
    transition: all 0.3s;
  }
}

.video-library {
  ::-webkit-scrollbar-track {
    background-color: #bcc1d1;
    border-radius: 1em;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 1em;
    background-color: use-color('green');
  }

  ::-webkit-scrollbar {
    width: 10px;
  }

  &__layout {
    display: grid;
    justify-content: center;
    height: 460px;
    margin: 10px 5px;
    overflow: auto;
    grid-template-columns: repeat(auto-fit, 140px);
    grid-template-rows: repeat(auto-fill, 180px);
    gap: 10px;
  }

  &__path-layout {
    display: flex;
    align-items: center;
    width: 95%;
    padding: 6px 10px;
    margin: 10px auto;
    background-color: use-color('light-dim');
    border-radius: 5px;
  }

  &__path-circle {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    background-image: url('../../assets/icons/library-up-menu.svg');
    background-size: 20px;
    background-repeat: no-repeat;
  }

  &__path {
    max-width: 85%;
    font-size: 14px;
    line-height: 16px;
  }

  &__path-item {
    color: use-color('dark');

    &:active {
      color: #0a0a0a;
    }
  }

  &__folders-wrapper {
    height: 30px;
  }

  &__folders-layout {
    position: relative;
    z-index: 1;
    overflow: hidden;
    width: 95%;
    margin: 10px auto;
    background-color: use-color('white');
    border: 1px solid use-color('light-dim');
    border-radius: 5px;
    font-size: 14px;
    line-height: 16px;
  }

  &__folders {
    display: flex;
    align-items: center;
    height: 30px;
    padding-left: 40px;
    background-image: url('../../assets/icons/bottom-arrow.svg');
    background-repeat: no-repeat;
    background-size: 20px;
    background-position: 10px 4px;
    cursor: pointer;

    &--active {
      background-image: url('../../assets/icons/top-arrow.svg');
    }
  }

  &__folder {
    display: flex;
    align-items: center;
    height: 30px;
    padding-left: 40px;
    background-color: use-color('white');
    cursor: pointer;

    &:hover {
      background-color: use-color('light');
    }
  }

  &__item {
    position: relative;
    width: 140px;
    height: 180px;
    background: use-color('light-dim');
    border-radius: 5px;
  }

  &__format {
    position: absolute;
    top: 17px;
    right: 20px;
    width: 32px;
    height: 18px;
    padding: 4px;
    background: use-color('green');
    border-radius: 3px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 12px;
    color: use-color('white');
  }

  &__preview {
    display: flex;
    width: 100px;
    height: 74px;
    margin: 17px auto 0;
    background-color: use-color('light-dim');

    &-image {
      min-width: 100%;
      max-height: 100%;
      border-radius: 3px;
    }
  }

  &__checkbox {
    /* stylelint-disable-next-line declaration-no-important */
    position: absolute !important;
    top: 75px;
    left: 25px;

    & div {
      width: 12px;
      height: 12px;
      background-size: 9px;
    }
  }

  &__info {
    text-align: center;
    line-height: 14px;
    font-size: 12px;
  }

  &__info-container {
    width: 130px;
    padding: 3px;
    margin: 0 auto;
    overflow: hidden;
    border: solid 1px use-color(dark);
    background-color: use-color('white');
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__name {
    display: block;
    padding: 6px 9px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__status {
    width: 75px;
    padding-left: 12px;
    margin: 0 auto 3px;
    background-image: url('../../assets/icons/file-enable.svg');
    background-size: 8px;
    background-position: left;
    background-repeat: no-repeat;
    text-transform: lowercase;
  }

  &__size {
    display: inline-block;
    width: 65px;
    height: 20px;
    padding: 3px 0;
    background: use-color('white');
    border: 1px solid use-color('dark');
  }

  &__time,
  &__file-size {
    padding: 0 0.5em;
  }

  &__time {
    border-right: solid 1px use-color(dark);
  }

  &__size {
    width: 130px;
    border-top: none;
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 95%;
    max-width: 440px;
    height: 40px;
    margin: 0 auto;
    background: use-color('dark');
    border-radius: 6px;
  }

  &__action {
    height: 20px;
    padding-left: 30px;
    background-size: 20px 20px;
    background-repeat: no-repeat;
    background-position: left;
    text-align: right;
    font-size: 12px;
    color: use-color('white');
    text-transform: lowercase;

    &:nth-child(1) {
      background-image: url('../../assets/icons/select-white.svg');
    }

    &:nth-child(2) {
      background-image: url('../../assets/icons/upload.svg');
    }

    &:nth-child(3) {
      background-image: url('../../assets/icons/trash-white.svg');
    }
  }
}

.player {
  &__layout {
    grid-area: player;
  }

  &__video-container {
    position: relative;
  }

  &__container {
    position: relative;
    width: 90%;
    margin: 20px auto 0;
    padding: 0.1px;
    background-color: use-color('dark');
    border-radius: 10px;

    &.fullscreen {
      z-index: 1101;
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      height: 100vh;
      margin: 0;
      border-radius: 0;

      .preview-canvas__canvas {
        max-height: 80vh;
      }
    }
  }

  &__body {
    width: 100%;
    height: auto;
    margin: 15px auto 0;
    background: #000;
  }

  &__bar {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin: 0 20px;
  }

  &__bar-left {
    margin-top: 20px;
  }

  &__button {
    display: inline-block;
    margin-left: 20px;
    background-repeat: no-repeat;
    background-position: left;
    transition: all 0.2s;
  }

  &__rename-project {
    display: flex;
    align-items: center;
    height: 40px;
  }

  &__rename-input {
    width: 275px;
    overflow: hidden;
    white-space: nowrap;
    text-align: left;
    text-overflow: ellipsis;
    color: use-color('white');
  }

  &__rename-button {
    width: 140px;
    height: 40px;
    margin-left: 5px;
    background-color: use-color('dark-green');
    border-radius: 4px;
    color: use-color('white');
  }

  &__save-as-button {
    width: 26px;
    height: 25px;
    background-size: 24px;
    background-image: url('../../assets/icons/save-as.svg');

    &:hover,
    &:active {
      background-image: url('../../assets/icons/save-as-hover.svg');
    }
  }

  &__cancel-project {
    width: 20px;
    height: 24px;
    background-size: 20px 24px;
    background-image: url('../../assets/icons/cancel-project.svg');

    &:hover,
    &:active {
      background-image: url('../../assets/icons/cancel-project-hover.svg');
    }
  }

  &__bar-right {
    display: flex;
    justify-content: space-between;
    width: 300px;
    margin-top: 20px;
  }

  &__button-rendering {
    width: 140px;
    height: 40px;
    background-color: use-color('dark-green');
    border-radius: 4px;
    color: use-color('white');
  }

  &__file-name {
    position: relative;
  }

  &__send {
    width: 140px;
    height: 40px;
    padding-right: 31px;
    background-color: use-color('dark-green');
    border-radius: 4px;
    background-image: url('../../assets/icons/send.svg');
    background-repeat: no-repeat;
    background-position: 110px center;
    color: use-color('white');
  }

  &__devices {
    z-index: 100;
    position: absolute;
    top: calc(100% + 10px);
    right: 0;
    width: 850px;
    height: 440px;
    background: use-color('dark');
    border-radius: 5px;

    .table__wrapper {
      height: 400px;
    }

    .table__head {
      th {
        font-size: 14px;
        line-height: 16px;
        text-overflow: ellipsis;
        white-space: nowrap;

        span {
          display: inline-block;
        }

        &[data-accessor='checkbox'] {
          min-width: 50px;
          width: 50px;
          padding: 0;
          border-right: 2px solid use-color('white');
        }
      }
    }

    .table__body {
      td {
        font-size: 14px;
        line-height: 16px;
      }
    }

    .table__icon-name {
      padding-right: 10px;
    }
  }

  &__devices-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    padding: 0 40px 0 20px;
    font-size: 10px;
    color: use-color('white');
  }

  &__devices-name {
    display: inline-block;
    width: 14px;
    height: 14px;
    margin: 0 15px 0 7px;
    background-image: url('../../assets/icons/status-sort.svg');
  }

  &__devices-left {
    display: flex;
    align-items: center;
  }

  &__devices-right {
    text-transform: lowercase;
  }

  &__devices-total,
  &__devices-selected,
  &__devices-button,
  &__devices-find {
    display: inline-block;
  }

  &__devices-button {
    width: 10px;
    height: 10px;
    margin-right: 17px;
    background-image: url('../../assets/icons/player-button.svg');
  }

  &__devices-input {
    width: 150px;
    height: 20px;
    padding: 10px;
    margin-left: 10px;
    background-color: use-color('white');
    border-radius: 3px;
    text-align: right;
    font-size: 10px;
  }

  &__devices-find-button {
    width: 16px;
    height: 18px;
    margin-left: 10px;
    background-image: url('../../assets/icons/player-search-devices.svg');
    background-repeat: no-repeat;
  }

  &__devices-total {
    margin-right: 20px;
  }

  &__control {
    margin: 8px 0;

    &-layout {
      width: 60px;
      height: 60px;
      margin: 0 auto;
    }

    &-button {
      position: relative;
      z-index: 1;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      background-image: url('../../assets/icons/play-button.svg');
      transition: all 0.1s;

      &:hover {
        background-image: url('../../assets/icons/play-button-hover.svg');
      }

      &--active {
        background-image: url('../../assets/icons/pause-button.svg');

        &:hover {
          background-image: url('../../assets/icons/pause-button-hover.svg');
        }
      }
    }
  }

  &__time {
    position: absolute;
    right: 20px;
    bottom: 20px;
    line-height: 19px;
    font-size: 16px;
    color: use-color('light-dim');
  }
}

.timeline-controls {
  &__layout {
    position: absolute;
    bottom: 10px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
  }

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 42%;
  }

  &__button {
    width: 58px;
    height: 58px;
    background-repeat: no-repeat;
    background-position: center;
    border: 2px solid transparent;
    border-radius: 5px;

    &:hover {
      border: 2px solid use-color('light-dim');
    }

    &:active {
      border: 2px solid use-color('gray');
    }
  }

  &__zoom {
    background-image: url('../../assets/icons/timeline-controls-zoom.svg');
    background-position: center;
    background-repeat: no-repeat;

    @media (max-width: 1550px) {
      width: 100px;
      height: 30px;
      background-size: 100px;
    }

    &--out {
      width: 60px;
      height: 58px;
      background-position-x: left;
    }

    &--in {
      width: 58px;
      height: 58px;
      background-position-x: right;
    }
  }

  &__cursor {
    background-image: url('../../assets/icons/timeline-controls-cursor.svg');
  }

  &__backward {
    background-image: url('../../assets/icons/timeline-controls-backward.svg');
  }

  &__forward {
    background-image: url('../../assets/icons/timeline-controls-forward.svg');
  }

  &__cut {
    background-image: url('../../assets/icons/timeline-controls-cut.svg');
  }

  &__delete {
    background-image: url('../../assets/icons/timeline-controls-delete.svg');
    background-position-x: 10px;
  }

  &__select {
    background-image: url('../../assets/icons/timeline-controls-select.svg');
  }

  &__time-display {
    position: relative;
  }

  &__time {
    display: inline-block;
    width: 150px;
    height: 40px;
    padding: 10px 25px;
    background: linear-gradient(
      90deg,
      use-color('gray-light') 66.33%,
      use-color('gray') 73.33%
    );
    border-radius: 6px;
    line-height: 19px;
    font-size: 16px;
    color: use-color('white');
  }

  &__time-button {
    position: absolute;
    right: 0;
    width: 40px;
    height: 40px;
    background: use-color('green');
    background-image: url('../../assets/icons/white-arrow.svg');
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 6px;
  }
}

.timeline {
  --timestamps-height: 20px;

  &__layout {
    display: grid;
    margin-top: 20px;
    overflow: hidden;
    grid-area: timeline;
    grid-template-columns: auto 1fr;
    user-select: none;
  }

  &__stamps {
    position: relative;
    z-index: 100;
    display: flex;
  }

  &__stamps-container {
    position: relative;
  }

  &__line {
    z-index: -1;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: calc(var(--timestamps-height) / 2);
    background-color: use-color('light-dim');
  }

  &__unit {
    height: var(--timestamps-height);
    background: use-color('light-dim');
  }

  &__unit-time {
    position: relative;
    height: 10px;
    background: use-color('gray');
    font-size: 8px;
    line-height: 9px;
    color: use-color('white');
  }

  &__unit,
  &__unit-time {
    width: var(--min-scale-length);
  }

  &__unit-time span {
    position: absolute;
    top: 50%;
    left: 100%;
    z-index: 100;
    display: block;
    transform: translate(-50%, -50%);
  }

  &__unit-scale {
    display: flex;
    justify-content: space-between;
    width: calc(100% + 1px);
    height: 50%;
    margin: 0 0 0 -1px;
  }

  &__unit-scale span {
    display: block;
    height: 50%;
    width: 1px;
    background: use-color('gray');
  }

  &__unit-scale span:nth-child(1) {
    background: 0;
  }

  &__unit-scale span:nth-child(6) {
    height: 100%;
  }

  &__aside,
  &__body {
    height: 170px;
  }

  &__aside {
    padding: var(--timestamps-height) 40px 0;
  }

  &__body {
    position: relative;
    overflow: hidden;
    overflow-x: auto;

    .timeline__track {
      &-layout {
        width: var(--timeline-body-width);
      }
    }
  }

  &__pointer {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1001;
    width: 20px;
    height: 150px;
    background-image: url('../../assets/icons/timeline-pointer.svg');
    transform: translateX(-50%);
    cursor: grab;

    &:active {
      cursor: grabbing;
    }

    &--preview {
      z-index: 801;
      visibility: hidden;
      opacity: 0.4;
      cursor: none;

      &:active {
        cursor: none;
      }
    }
  }

  &__track {
    &-layout {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 15px;
    }

    &-icon {
      width: 50px;
      height: 50px;
      background-position: center;
      background-repeat: no-repeat;

      &--video {
        background-image: url('../../assets/icons/timeline-video.svg');
      }

      &--audio {
        background-image: url('../../assets/icons/timeline-audio.svg');
      }
    }

    &-body {
      display: flex;
      height: 50px;
      width: 100%;
      background-color: use-color('light-dim');
      border-radius: 5px;
    }
  }
}

.file-track {
  &__layout {
    z-index: 901;
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    height: 50px;
    border-radius: 5px;
    background: use-color('gray');
    background-image: url('../../assets/icons/file-track-gradient.svg');
    background-repeat: no-repeat;
    font-size: 12px;
    line-height: 14px;
    color: use-color('white');
    cursor: pointer;
    background-position-x: left;

    &--prev {
      .file-track__group-right {
        background-image: linear-gradient(to left, #f00, transparent 20%);
      }
    }

    &--next {
      .file-track__group-left {
        background-image: linear-gradient(to right, #f00, transparent 20%);
      }
    }

    &--select {
      z-index: 902;
      opacity: 0.68;
      transform: scale(1.25);
    }
  }

  &__group {
    align-items: center;
    display: grid;
    height: 50px;
    grid-template-columns: auto 1fr;
  }

  &__group-left {
    position: sticky;
    left: 0;
    padding-right: 1em;
    background-color: use-color('gray');

    &:active {
      &::before {
        content: '';
        position: absolute;
        left: -18px;
        width: 24px;
        height: 50px;
        background-image: url('../../assets/icons/track-move-left.svg');
        background-repeat: no-repeat;
        background-position: center;
      }
    }
  }

  &__group-right {
    &:active {
      &::after {
        content: '';
        position: absolute;
        right: -12px;
        width: 24px;
        height: 50px;
        background-image: url('../../assets/icons/track-move-right.svg');
      }
    }
  }

  &__file-icon {
    display: inline-block;
    width: 18px;
    height: 12px;
    margin: 0 10px;
    background-position: center;
    background-repeat: no-repeat;

    &--video {
      background-image: url('../../assets/icons/video-file.svg');
    }

    &--audio {
      background-image: url('../../assets/icons/audio-file.svg');
    }

    &--image {
      background-image: url('../../assets/icons/image-file.svg');
    }
  }

  &__time {
    margin-right: 12px;
    font-size: 14px;
    line-height: 16px;
  }

  &__audio {
    display: inline-block;
    margin-right: 15px;
    width: 26px;
    height: 20px;
    background-image: url('../../assets/icons/file-track-audio.svg');
    background-position: center;
    background-repeat: no-repeat;

    &--off {
      width: 28px;
      background-image: url('../../assets/icons/file-track-audio-off.svg');
    }
  }

  &__name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.project-name {
  &__icon {
    width: 60px;
    height: 55px;
    margin: 0 auto 18px;
    background-image: url('../../assets/icons/video-project-icon.svg');
  }

  &__title {
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: 400;
    line-height: 21px;
    text-align: center;
    color: use-color('white');
  }

  &__input {
    width: 400px;
    height: 40px;
    padding: 10px;
    margin-bottom: 20px;
    background-color: use-color('white');
    border-radius: 10px;
    background-image: url('../../assets/images/loader.png');
    background-repeat: no-repeat;
    background-size: 30px;
    background-position-x: 360px;
    background-position-y: center;
  }

  &__create-button {
    display: block;
    width: 140px;
    height: 40px;
    margin: 0 auto;
    background-color: use-color('dark-green');
    border: 3px solid use-color('dark-green');
    border-radius: 4px;
    color: use-color('white');

    &:hover {
      border: 3px solid use-color('light-dim');
    }

    &:active {
      border: 3px solid rgba(0, 0, 0, 0.2);
    }
  }

  &__error-container {
    width: 400px;
    height: 80px;
    padding: 10px 0;
    margin-top: 20px;
    background-color: use-color('red');
    border-radius: 10px;
    color: use-color('white');
  }

  &__error-title {
    width: 130px;
    height: 22px;
    margin-left: 139px;
    background-image: url('../../assets/icons/project-error-icon.svg');
    background-size: 20px 18px;
    background-repeat: no-repeat;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    color: use-color('white');
    background-position-x: left;
    background-position-y: center;
  }

  &__error-value {
    margin-top: 14px;
    text-align: center;
  }

  &__form {
    padding: 0;
  }
}
