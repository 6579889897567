@import '../../styles/variables.scss';

.library {
  &__layout {
    padding: 20px;
    padding-bottom: 0;
  }

  &__wrapper {
    width: auto;
    margin: 15px 50px 30px;
    padding: 0 25px 25px;
    background-color: use-color('white');
    border-radius: 5px;

    .add__edit__title {
      font-weight: normal;
    }
  }

  &__head {
    display: flex;
    align-items: center;
    height: 50px;
    border-bottom: 1px dashed use-color('dark');
    font-size: 14px;
  }

  &__sort {
    padding-left: 25px;
    background-image: url('../../assets/icons/double-arrow-grey.svg');
    background-size: 7px 15px;
    background-position: left;
    background-repeat: no-repeat;
  }

  &__create-folder {
    width: 45px;
    height: 30px;
    margin-left: 20px;
    background-image: url('../../assets/icons/create-folder.svg');
    background-size: 45px 30px;
    background-repeat: no-repeat;
    background-position: center;
  }

  &__copy-media {
    width: 31px;
    height: 30px;
    margin-left: 20px;
    background-image: url('../../assets/icons/copy-media.svg');
    background-size: 31px 30px;
    background-repeat: no-repeat;
  }

  &__cut-media {
    width: 37px;
    height: 30px;
    margin-left: 20px;
    background-image: url('../../assets/icons/cut-media.svg');
    background-size: 37px 30px;
    background-repeat: no-repeat;
  }

  &__paste-media {
    width: 31px;
    height: 30px;
    margin-left: 20px;
    background-image: url('../../assets/icons/paste-media.svg');
    background-size: 31px 30px;
    background-repeat: no-repeat;
  }

  &__delete-media {
    width: 28px;
    height: 28px;
    margin-left: 20px;
    background-image: url('../../assets/icons/delete-bin-gray.svg');
    background-size: 28px;
    background-repeat: no-repeat;
  }

  &__folder-path-layout {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 6px 10px;
    margin-top: 10px;
    background-color: #dbe0f2;
    border-radius: 5px;
  }

  &__path-circle {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    background-image: url('../../assets/icons/library-up-menu.svg');
    background-size: 20px;
    background-repeat: no-repeat;
  }

  &__folder-path {
    max-width: 95%;
    font-size: 14px;
    line-height: 16px;
  }

  &__folder-path-item {
    color: use-color('dark');

    &:active {
      color: #0a0a0a;
    }
  }

  &__list {
    display: grid;
    height: 400px;
    margin-top: 10px;
    overflow-y: auto;
    grid-template-columns: repeat(auto-fit, 140px);
    grid-auto-rows: 190px;
    gap: 20px;

    &__item {
      &-delete {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 0.25em;
        border-radius: 2em;
        transition: all 0.3s;

        &:hover,
        &:active {
          width: 100%;
          border-radius: 0.25em;
          background-color: use-color('white');
        }
      }
    }
  }

  &__item {
    width: 140px;
    height: 190px;
    border: solid 2px #dbe0f2;
    border-radius: 5px;
    background-color: #dbe0f2;

    &:hover {
      border-color: use-color('green');
    }

    &--empty {
      border: solid 2px #dbe0f2;
      border-radius: 5px;
      background-color: #dbe0f2;
    }
  }

  &__item-actions {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  &__item-download {
    display: inline-block;
    margin-bottom: 8px;
    color: use-color('dark');
    cursor: pointer;

    &:hover,
    &:active {
      color: #0a0a0a;
    }
  }

  /* stylelint-disable order/properties-order */
  &__item-checkbox {
    appearance: none;
    width: 20px;
    height: 20px;
    background-color: use-color('white');
    border: 3px solid use-color('green');
    border-radius: 3px;
    cursor: pointer;

    &:checked {
      background-image: url('../../assets/icons/check-green.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: 14px;

      &:hover {
        background-color: use-color('green');
        background-image: url('../../assets/icons/check-hover.svg');
      }
    }
  }
  /* stylelint-enable order/properties-order */
}
