@import '../../styles/variables.scss';

.monitor {
  /* stylelint-disable declaration-no-important */
  &__add-edit-layout {
    .button-secondary {
      display: inline-block;
      width: unset;
      padding: 12px 15px;
    }
  }

  &__add-edit-form {
    max-width: 800px;
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 1500px) {
      max-width: none;
      flex-direction: row;
      justify-content: space-between;
    }

    .add__edit__field {
      margin-bottom: 30px;
    }
  }

  &__form-column {
    @media screen and (min-width: 1500px) {
      width: 45%;
    }
  }

  &__file-label {
    flex: 0;
  }

  &__input-info {
    width: 270px !important;
    background-position-x: 240px;

    &--angle,
    &--brightness {
      width: max(100%, 100px) !important;
    }
  }

  &__monitor {
    &__layout {
      padding: 2em;
      border-radius: 3px;
      background-color: use-color('light');
    }

    &__field {
      flex-basis: 40%;

      label {
        padding-left: 0 !important;
        font-size: 14px !important;
      }

      &-label {
        position: absolute;
        top: -1.25em;
        font-size: 14px;
      }

      &-model {
        label {
          padding: 1em !important;
        }
      }

      &__wrapper {
        margin-top: 40px;

        @include mobile {
          flex-flow: column;
        }
      }

      &__wrapper > & {
        &:not(:last-child) {
          margin-right: 1em;
          margin-bottom: 0;

          @include mobile {
            margin-right: 0;
            margin-bottom: 1em;
          }
        }
      }
    }
  }

  /* stylelint-enable declaration-no-important */
  &__field-coordinates {
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      margin: 0;
      -webkit-appearance: none;
    }

    input[type='number'] {
      -moz-appearance: textfield;
    }
  }

  &__map {
    position: relative;
    height: 40vh;
    min-height: 300px;
    margin-bottom: 20px;
  }

  &__filter {
    position: absolute;
    bottom: 10px;
    right: 10px;
    width: 270px;
    height: 280px;
    padding: 10px;
    background-color: use-color('white');
    border-radius: 5px;
    font-size: 12px;
    line-height: 14px;

    .form {
      padding: 0;
    }
  }

  &__filter-input {
    height: 30px;
    background-color: use-color('white');
    border: 1px solid use-color('gray-light');
    border-radius: 2px;
    text-align: center;
    color: use-color('dark');

    &::placeholder {
      color: use-color('light-dim');
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      margin: 0;
      -webkit-appearance: none;
    }

    &[type='number'] {
      -moz-appearance: textfield;
    }

    &[disabled] {
      opacity: 0.6;
    }
  }

  &__filter-address {
    width: 100%;
  }

  &__filter-coordinates {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
  }

  &__filter-coordinate {
    width: 100px;

    &--first {
      margin-right: 5px;
    }
  }

  &__filter-radius-layout {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 10px;
  }

  &__filter-radius {
    width: 100px;
  }

  &__filter-radius-decor {
    position: absolute;
    left: 160px;
    width: 4px;
    height: 10px;
    background-image: url('../../assets/icons/date-picker-month-hover.svg');
    background-size: 4px 10px;

    &--second {
      left: unset;
      right: 10px;
      transform: rotate(180deg);
    }
  }

  &__filter-radius-select-layout {
    display: flex;
    width: 250px;
    height: 27px;
    padding: 3px;
    background-color: use-color('gray');
    border-radius: 3px;
  }

  &__filter-radius-select {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-right: solid 2px use-color('gray-light');
    font-size: 12px;
    line-height: 14px;
    color: use-color('white');

    &:last-child {
      border: none;
    }

    &:hover,
    &:active {
      background-color: #5e6577;
    }

    &.checked {
      background-color: use-color('green');
    }
  }

  &__filter-address-layout,
  &__filter-category-layout {
    position: relative;
    width: 100%;
    background-color: use-color('white');

    &--open {
      > .list {
        position: absolute;
        left: 0;
        z-index: 1000;
        width: 100%;
        padding-bottom: 5px;
        background-color: #fff;
        border: 1px solid #888fa3;
        border-top: 0;
        border-radius: 2px;
      }
    }

    &--disabled {
      opacity: 0.6;
    }
  }

  &__filter-category-layout {
    margin-top: 10px;
    border: 1px solid use-color('gray-light');
    border-radius: 2px;

    .list {
      left: -1px;
      width: calc(100% + 2px);
    }
  }

  &__filter-category {
    width: 100%;
    height: 30px;
    padding: 7px;
    background-image: url('../../assets/icons/bottom-arrow.svg');
    background-repeat: no-repeat;
    background-size: 24px;
    background-position: 7px;
    text-align: right;
    cursor: pointer;

    &--open {
      background-image: url('../../assets/icons/top-arrow.svg');
    }
  }

  &__filter-address-option {
    i {
      text-decoration: none;
      color: use-color('green');
    }
  }

  &__filter-category-option {
    justify-content: space-between;

    .form__checkbox-mask--gray {
      border: solid 3px use-color('gray-light');
    }
  }

  &__filter-address-option,
  &__filter-category-option {
    display: flex;
    align-items: center;
    min-height: 2em;
    padding: 3px 7px;

    &:hover {
      background-color: use-color('light-dim');
    }

    &.checked {
      background-color: use-color('green');
    }
  }

  &__filter-search {
    position: relative;
    width: 200px;
    height: 30px;
    margin: 20px 25px 0;
    font-size: 18px;
    line-height: 21px;

    &::after {
      content: '';
      position: absolute;
      top: 8px;
      left: 52px;
      width: 16px;
      height: 16px;
      background-image: url('../../assets/icons/filter-name.svg');
      background-repeat: no-repeat;
      background-size: 16px;
    }

    &--disabled {
      opacity: 0.6;
      cursor: default;
    }
  }

  &__filter-clean,
  &__filter-status {
    height: 20px;
    margin-top: 7px;
    color: use-color('dark');
  }

  &__filter-clean {
    padding-left: 24px;
    margin-left: 30px;
    background-image: url('../../assets/icons/monitors-filter-clean.svg');
    background-repeat: no-repeat;
    background-size: 12px;
    background-position: 4px;
    transition: all 0.1s;

    &:hover,
    &:active {
      background-image: url('../../assets/icons/monitors-filter-clean-active.svg');
    }
  }

  &__filter-status {
    display: block;
    text-align: center;
  }

  &__table {
    .table__wrapper {
      max-height: calc(40vh - 30px - 2em);
    }

    .table__head {
      tr {
        th {
          &[data-accessor='name'] {
            min-width: 170px;
          }

          &:nth-child(4),
          &:nth-child(5),
          &:nth-child(8) {
            min-width: 210px;
          }

          &:nth-child(5),
          &:nth-child(6),
          &:nth-child(9) {
            min-width: 140px;
          }
        }
      }
    }

    .table__body {
      tr {
        td:nth-child(6) {
          font-size: 0.8em;
        }

        &.selected {
          td:nth-child(1) {
            background: linear-gradient(
                90deg,
                use-color('green') 0%,
                use-color('white') 7%
            );
          }
        }

        &.selected:nth-child(even) {
          td:nth-child(1) {
            background: linear-gradient(
                90deg,
                use-color('green') 0%,
                use-color('light') 7%
            );
          }
        }
      }
    }
  }

  &__orientation {
    &__layout {
      position: relative;
      justify-content: space-around;
      align-items: baseline;
      height: 8em;
      font-size: 14px;
    }

    &__icon {
      &_h,
      &_v {
        width: 10em;
        height: 5em;
        background-repeat: no-repeat;
        background-position: center center;
        text-align: center;
        line-height: 12.5em;
      }

      &_h {
        background-image: url(../../assets/icons/monitor-horizontal.svg);
      }

      &_v {
        background-image: url(../../assets/icons/monitor-vertical.svg);
        line-height: 12em;
      }
    }
  }

  &__table-filter {
    border: dashed 1px use-color('gray-light');
    border-radius: 0.5em;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
    margin-bottom: 1em;

    &.form {
      padding: 9px 16px;

      fieldset {
        span {
          font-size: 13px;
        }
      }
    }
  }

  &-precalc-form {
    align-items: center;
    display: flex;
    column-gap: 12px;
    flex-direction: row;
    justify-content: flex-end;
    padding: 0 0 15px;

    &__calendar {
      background: use-color('gray');
      align-items: center;
      display: flex;
      column-gap: 6px;
      padding-left: 4px;
      color: white;
      border: solid 2px use-color('gray');
      border-radius: 4px;
      font-size: 14px;

      .react-date-picker,
      .react-daterange-picker {
        &__wrapper {
          //padding: 2px;
        }

        &__inputGroup {
          background: white;
          height: 21.2px;
          //color: white;
          width: unset;
          padding: 0 12px;
        }
      }
    }

    &__output {
      display: flex;
      flex-wrap: nowrap;
      white-space: nowrap;
      background: white;
      border: solid 3px use-color('dark');
      border-radius: 4px;
      padding: 4px 10px 4px 5px;
      font-size: 14px;
    }

    &__button {
      font-size: 14px;
      width: 150px;
      white-space: nowrap;
      height: 33px;
    }
  }
}

.add__edit__field__input_wrapper {
  .form__radio {
    input[type='radio'] {
      &:checked + label {
        .monitor__orientation__icon {
          &_h,
          &_v {
            color: use-color('green');
          }

          &_h {
            background-image: url(../../assets/icons/monitor-horizontal-green.svg);
          }

          &_v {
            background-image: url(../../assets/icons/monitor-vertical-green.svg);
          }
        }
      }
    }
  }
}

.screenshot {
  &__layout {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 20px;
  }

  &__image {
    width: 100px;
    margin-right: 20px;
  }

  &__button {
    width: 200px;
    height: 60px;
  }
}

/* stylelint-disable no-descending-specificity */
.add__edit {
  &__address {
    .add__edit__form__field:not(:first-child) {
      margin-top: 24px;
    }
  }

  &__coords {
    .add__edit__form__field,
    .add__edit__form__field:not(:first-child) {
      margin-top: 0;

      .form__input {
        width: 95%;
      }
    }

    display: flex;
    width: 300px;
    margin-bottom: 15px;
  }

  &__gps {
    -moz-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      margin: 0;
      -webkit-appearance: none;
    }
  }
}

/* stylelint-enable no-descending-specificity */
