@import '../../styles/variables.scss';

.filters {
  &__search-button {
    width: 105px;
    height: 40px;
    padding-left: 32px;
    background-color: use-color('dark-green');
    background-image: url('../../assets/icons/filter-name.svg');
    background-repeat: no-repeat;
    background-position: 13px;
    border: 3px solid use-color('dark-green');
    border-radius: 2px;
    text-align: left;
    font-size: 18px;
    line-height: 21px;
    color: use-color('white');
    transition: all 0.1s;

    &:hover {
      border: 3px solid use-color('light-dim');
    }

    &:active {
      border: 3px solid rgba(0, 0, 0, 0.2);
    }
  }

  &__reset-button {
    width: 100px;
    height: 40px;
    margin-left: 5px;
    border: 1px solid use-color('gray-light');
    border-radius: 2px;
    font-size: 18px;
    line-height: 21px;
    color: use-color('dark');
  }

  &__text-layout {
    position: absolute;
    z-index: 100;
    top: 38px;
    left: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 400px;
    height: 70px;
    padding: 0 10px 0 15px;
    background-color: use-color('white');
    transform: translateX(-50%);
    filter: drop-shadow(0 4px 8px rgba(71, 79, 100, 0.6));
  }

  &__text-input-layout {
    position: relative;
    width: 250px;
    margin-right: 10px;
  }

  &__text-input {
    width: 250px;
    height: 40px;
    padding-left: 10px;
    border: 1px solid use-color('gray-light');
    border-radius: 2px;
    font-size: 18px;
    line-height: 21px;
    transition: all 0.1s;

    &:hover {
      border: 1px solid use-color('white');
      box-shadow: 0 0 0 3px use-color('light-dim');
    }

    &:focus {
      border: 1px solid use-color('gray-light');
      box-shadow: 0 0 0 3px use-color('light-dim');
    }
  }

  &__text-reset {
    position: absolute;
    top: 14px;
    right: 14px;
    width: 12px;
    height: 12px;
    background-image: url('../../assets/icons/text-reset.svg');
    transition: all 0.1s;

    &:hover {
      background-image: url('../../assets/icons/text-reset-hover.svg');
    }

    &:active {
      background-image: url('../../assets/icons/text-reset-active.svg');
    }
  }

  &__date-layout {
    position: absolute;
    z-index: 100;
    top: 38px;
    left: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 255px;
    background-color: use-color('white');
    box-shadow: 0 4px 8px rgba(71, 79, 100, 0.5);
    transform: translateX(-50%);
  }

  &__date-labels {
    position: absolute;
    top: 11px;
    left: 45px;
  }

  &__date-label {
    margin-bottom: 15px;
    font-size: 14px;
    line-height: 16px;
    text-align: end;
    color: use-color('dark');
  }

  &__date-next-month {
    width: 5px;
    height: 8px;
    margin-right: 30px;
    background-image: url('../../assets/icons/date-picker-month.svg');

    &:hover {
      background-image: url('../../assets/icons/date-picker-month-hover.svg');
    }

    &:active {
      background-image: url('../../assets/icons/date-picker-month-active.svg');
    }
  }

  &__date-prev-month {
    width: 5px;
    height: 8px;
    margin-right: 0;
    margin-left: 30px;
    background-image: url('../../assets/icons/date-picker-month.svg');
    transform: rotate(180deg);

    &:hover {
      background-image: url('../../assets/icons/date-picker-month-hover.svg');
    }

    &:active {
      background-image: url('../../assets/icons/date-picker-month-active.svg');
    }
  }

  &__date-next-year {
    width: 12px;
    height: 8px;
    background-image: url('../../assets/icons/date-picker-year.svg');

    &:hover {
      background-image: url('../../assets/icons/date-picker-year-hover.svg');
    }

    &:active {
      background-image: url('../../assets/icons/date-picker-year-active.svg');
    }
  }

  &__date-prev-year {
    width: 12px;
    height: 8px;
    background-image: url('../../assets/icons/date-picker-year.svg');
    transform: rotate(180deg);

    &:hover {
      background-image: url('../../assets/icons/date-picker-year-hover.svg');
    }

    &:active {
      background-image: url('../../assets/icons/date-picker-year-active.svg');
    }
  }

  &__date-actions {
    margin: 0 auto 7px;
  }

  &__radio-layout {
    position: absolute;
    z-index: 100;
    top: 38px;
    left: 50%;
    width: 160px;
    padding: 25px 15px 15px;
    background-color: use-color('white');
    white-space: normal;
    transform: translateX(-50%);
    filter: drop-shadow(0 4px 8px rgba(71, 79, 100, 0.6));
  }

  &__radio-input-layout {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  &__radio-label {
    margin-left: 10px;
    font-size: 16px;
    line-height: 18px;
    color: use-color('dark');
  }

  &__radio-search {
    margin: 0 13px;
  }

  .react-date-picker,
  .react-daterange-picker {
    &__calendar {
      &--closed {
        display: none;
      }
    }
  }
}
