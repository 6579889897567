@import '../../../styles/variables.scss';

.button {
  display: block;
  width: 100%;
  padding: 0.85em 0;
  border: 0;
  font-size: 1em;
  text-decoration: none;
  text-align: center;
  transition: all 0.3s;
  cursor: pointer;

  &-text {
    background-color: transparent;
    border-bottom: dashed 1px;

    &:hover,
    &:active {
      border-style: solid;
    }
  }

  &-primary {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 110px;
    height: 40px;
    border-radius: 3px;
    background: linear-gradient(use-color('green'), use-color('green'));
    border: 2px solid use-color('green');
    color: use-color('white');

    &:hover {
      background: linear-gradient(
        180deg,
        use-color('dark-green') 0%,
        use-color('green') 100%
      );
    }

    &:active {
      border: 2px solid use-color('dark-green');
    }
  }

  &-text-primary {
    border-color: use-color('green');
    color: use-color('green');

    &:hover,
    &:active {
      border-color: darken(use-color('green'), 5%);
      color: darken(use-color('green'), 5%);
    }
  }

  &-secondary {
    width: 110px;
    height: 40px;
    border-radius: 3px;
    background-color: use-color('gray');
    color: use-color('white');

    &:hover,
    &:active {
      background-color: darken(use-color('gray'), 5%);
    }
  }

  &-text-secondary {
    border-color: use-color('gray');
    color: use-color('gray');

    &:hover,
    &:active {
      border-color: darken(use-color('gray'), 5%);
      color: darken(use-color('gray'), 5%);
    }
  }

  &-rounded {
    border-radius: 0.5em;
  }

  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }
}
