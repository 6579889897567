.page-plate {
  --icon-size: 1.25em;
  --gap: 10px;
  --row-height: 35px;
  background-color: white;
  border-radius: 12px;
  display: inline-block;
  padding-bottom: 8px;

  &__header,
  &__body {
    padding-left: 14px;
  }

  &__header {
    align-items: center;
    border-bottom: dotted 2px black;
    display: flex;
    justify-content: space-between;
    height: 50px;
    padding-right: 16px;
  }

  &__body {
    padding-right: 32px;
  }

  &__name {
    align-items: center;
    display: flex;
  }

  &__icon {
    background-size: contain;
    background-repeat: no-repeat;
    display: inline-block;
    width: var(--icon-size);
    height: var(--icon-size);
  }

  &__name > &__icon {
    margin-right: 12px;
  }

  &__button {
    width: unset;
    height: unset;
    line-height: var(--row-height);
    border: 0;
  }

  &__form {
    padding: unset;
  }

  &__fieldset {
    display: grid;
    margin-top: 10px;
    row-gap: var(--gap);

    &-legend {
      font-size: 14px;
      padding: 5px 0 15px;
    }

    &.buttons {
      justify-content: flex-end;
    }
  }

  &__field {
    align-items: center;
    display: flex;
    justify-content: space-between;
    column-gap: 14px;

    &-desc {
      font-size: 14px;
    }
  }

  &__input {
    border: solid 1px #676767;
    border-radius: 6px;
    font-size: 15px;
    padding: 10px 12px;
    text-align: right;
  }

  &__row {
    align-items: center;
    column-gap: var(--gap);
    display: flex;
    font-size: 13px;
    height: var(--row-height);
    line-height: var(--row-height);

    &-item {
      background-color: #f1f1f1;
      border-radius: 4px;
      min-width: 86px;
      text-align: center;

      > span {
        vertical-align: middle;
      }

      &:nth-of-type(2) {
        margin-left: calc(var(--gap) * 2);
      }
    }
  }

  &__row-item,
  &__button {
    padding: 0 5px;
  }

  &__header &__button {
    padding: 5px;
  }
}
