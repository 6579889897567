@import '../../styles/variables.scss';

.form {
  display: flex;
  flex-direction: column;
  padding: 5em 4em 3em 4em;

  @include mobile {
    padding: 5em 3em 3em 3em;
  }

  &-no-padding {
    padding: 0;
  }

  &__checkbox {
    & label > span:not(:empty) {
      margin-left: 0.75em;
    }

    &-mask {
      position: relative;
      border-radius: 4px;
      transition-property: background-color;
      transition-duration: 150ms;
      transition-timing-function: ease-out;

      &--big {
        width: 25px;
        height: 25px;
      }

      &--small {
        width: 15px;
        height: 15px;
        background-size: 10px;
      }

      &--green {
        border: solid 2px use-color('green');
        background-color: use-color('white');
      }

      &--dark {
        border: solid 2px use-color('dark-light');
        background-color: use-color('white');
      }

      &--gray {
        border: solid 2px use-color('gray-light');
        background-color: use-color('white');
      }
    }
  }

  &__radio {
    &-mask {
      position: relative;
      width: 21px;
      height: 21px;
      background-color: #fefefe;
      border: 2px solid use-color('gray');
      border-radius: 50%;

      &:focus,
      &:active {
        &::before {
          content: '';
          position: absolute;
          top: -7px;
          right: -7px;
          display: block;
          width: 23px;
          height: 23px;
          border: 4px solid use-color('light-dim');
        }
      }

      &::after,
      &::before {
        border-radius: 50%;
      }
    }
  }

  &__field {
    position: relative;

    & > input,
    & > label,
    & > .form__checkbox-mask::before,
    & > .form__checkbox-mask::after,
    & > .form__radio-mask::before,
    & > .form__radio-mask::after {
      transition-duration: 150ms;
      transition-timing-function: ease-out;
    }

    &:not(:last-child) {
      position: relative;
    }

    & > label {
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      color: use-color('dark');
    }

    &.form__native > label {
      position: absolute;
      top: 15%;
      left: 2%;

      &:hover .form__checkbox-mask {
        background-color: use-color('dark-light');
      }
    }
  }

  &__input {
    width: 100%;
    height: 2em;
    border: none;
    background-color: transparent;
    border-bottom: solid 1px use-color('dark');
    font-size: 1em;

    &__file {
      // Do any CSS style here
    }

    &:focus + label,
    &:not(:placeholder-shown) + label,
    &:-webkit-autofill + label {
      top: -24%;
      left: 0;
      color: use-color('green');
      font-size: 0.75em;
    }

    &:focus {
      border-color: use-color('green');
    }

    &__select,
    &__file {
      padding: 0;
      appearance: none;
    }

    &__select {
      background-image: url('../../assets/icons/circle-arrow-down.svg');
      background-size: 22px;
      background-repeat: no-repeat;
      cursor: pointer;
      background-position-x: 270px;
      background-position-y: 6px;
    }

    &__file {
      display: none;

      & + label > &-icon {
        height: 1.5em;
        padding-left: 2em;
        background: url(../../assets/icons/added.svg) left center no-repeat;
        font-size: 14px;
        line-height: 1.5em;
      }

      & + label:hover > &-icon,
      & + label:active > &-icon {
        background-image: url(../../assets/icons/added-hover.svg);
      }
    }
  }

  &__field > input[type='radio'] {
    display: none;

    &:disabled + label {
      cursor: default;

      & > .form__radio-mask {
        display: flex;
        background-color: #fefefe;
        border-color: use-color('gray-light');

        &::after {
          content: '';
          width: 11px;
          height: 11px;
          margin: auto;
          background-color: use-color('gray-light');
        }
      }
    }

    &:checked + label {
      & > .form__radio-mask {
        display: flex;
        background-color: #fefefe;
        border-color: use-color('green');

        &::after {
          content: '';
          width: 11px;
          height: 11px;
          margin: auto;
          background-color: use-color('green');
        }

        &:focus,
        &:active {
          &::before {
            border-color: #c2e3c2;
          }
        }

        &--gray {
          border-color: use-color('dark');

          &::after {
            content: '';
            width: 11px;
            height: 11px;
            margin: auto;
            background-color: use-color('dark');
          }

          &:focus,
          &:active {
            &::before {
              border-color: use-color('light-dim');
            }
          }
        }
      }
    }
  }

  &__field > input[type='checkbox'] {
    display: none;

    &:disabled + label {
      cursor: default;

      & > .form__checkbox-mask {
        border-color: use-color(gray-light);
        background-color: use-color(light-dim);

        &::before,
        &::after {
          background-color: use-color(light-dim);
        }
      }
    }

    &:checked + label {
      & > .form__checkbox-mask--green {
        border-color: use-color('green');
        background-image: url('../../assets/icons/check-green.svg');
        background-repeat: no-repeat;
        background-position: center;
      }

      &:hover {
        & > .form__checkbox-mask--green {
          background-color: use-color('green');
          background-image: url('../../assets/icons/check-hover.svg');
          background-repeat: no-repeat;
          background-position: center;
        }
      }

      & > .form__checkbox-mask--dark {
        border-color: use-color('dark-light');
        background-image: url('../../assets/icons/check-dark.svg');
        background-repeat: no-repeat;
        background-position: center;
      }

      &:hover {
        & > .form__checkbox-mask--dark {
          background-color: use-color('dark-light');
          background-image: url('../../assets/icons/check-hover.svg');
          background-repeat: no-repeat;
          background-position: center;
        }
      }

      & > .form__checkbox-mask--gray {
        border-color: use-color('gray-light');
        background-image: url('../../assets/icons/check-gray.svg');
        background-repeat: no-repeat;
        background-position: center;
      }

      &:hover {
        & > .form__checkbox-mask--gray {
          background-color: use-color('gray-light');
          background-image: url('../../assets/icons/check-hover.svg');
          background-repeat: no-repeat;
          background-position: center;
        }
      }

      & > .form__checkbox-mask--light {
        border-color: use-color('gray-light');
        background-image: url('../../assets/icons/check-light.svg');
        background-repeat: no-repeat;
        background-position: center;
      }

      &:hover {
        & > .form__checkbox-mask--light {
          background-color: use-color('gray-light');
          background-image: url('../../assets/icons/check-hover.svg');
          background-repeat: no-repeat;
          background-position: center;
        }
      }
    }

    &:checked:disabled + label {
      & > .form__checkbox-mask {
        border-color: use-color(gray-light);
        background-color: use-color(light-dim);
        opacity: 0.75;

        &::before,
        &::after {
          background-color: use-color(gray-light);
        }
      }
    }
  }
}

.date-input {
  &.inlined {
    display: inline-block;

    .react-date,
    .react-daterange {
      &-picker {
        &__wrapper {
          width: unset;
          margin: unset;
          display: flex;
        }

        &__inputGroup {
          display: inline-flex;
          margin-top: 0;
        }

        &__calendar {
          background-color: white;
          box-shadow: 0 4px 5px rgba(0, 0, 0, 0.3);
          padding: 0.25em 1em;
          position: absolute !important;
          z-index: 1001;

          &--closed {
            display: none;
          }
        }
      }
    }
  }
}
