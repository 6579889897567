.menu__item__icon {
  &Desktop {
    background-image: url('../../assets/icons/desktop.svg');

    &--active {
      background-image: url('../../assets/icons/desktop-active.svg');
    }
  }

  &Applications {
    background-image: url('../../assets/icons/applications.svg');

    &--active {
      background-image: url('../../assets/icons/applications-active.svg');
    }
  }

  &Monitors {
    width: 31px;
    height: 20px;
    margin-left: 15px;
    background-image: url('../../assets/icons/monitors.svg');

    &--active {
      background-image: url('../../assets/icons/monitors-active.svg');
    }
  }

  &Playlists {
    background-image: url('../../assets/icons/playlists.svg');

    &--active {
      background-image: url('../../assets/icons/playlists-active.svg');
    }
  }

  &Library {
    background-image: url('../../assets/icons/library.svg');

    &--active {
      background-image: url('../../assets/icons/library-active.svg');
    }
  }

  &Settings {
    width: 21.5px;
    height: 20px;
    background-image: url('../../assets/icons/settings.svg');

    &--active {
      background-image: url('../../assets/icons/settings-active.svg');
    }
  }

  &Users {
    width: 25px;
    height: 20px;
    background-image: url('../../assets/icons/users.svg');

    &--active {
      background-image: url('../../assets/icons/users-active.svg');
    }
  }

  &Graphics {
    width: 22px;
    height: 20px;
    background-image: url('../../assets/icons/graphics.svg');

    &--active {
      background-image: url('../../assets/icons/graphics-active.svg');
    }
  }

  &Editor {
    width: 22px;
    height: 20px;
    background-image: url('../../assets/icons/editor.svg');

    &--active {
      background-image: url('../../assets/icons/editor-active.svg');
    }
  }

  &Profile {
    background-image: url('../../assets/icons/profile-settings.svg');
  }

  &DesktopStatistics {
    background-image: url('../../assets/icons/profile-statistics.svg');
  }

  &Logout {
    background-image: url('../../assets/icons/profile-logout-green.svg');
  }
}
