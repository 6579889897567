@import '../../styles/variables.scss';

.add__edit {
  &__timeline {
    &__header,
    &__footer {
      align-items: center;
      padding: 0.5em 1em;
      border-radius: 0.5em 0.5em 0 0;
      background-color: use-color('gray');
      color: use-color('white');

      .form__input {
        padding: 0 0.5em;
        border-radius: 5px;
        border-color: use-color('gray');
        background-color: use-color('white');
        color: use-color('dark');
      }

      .form__field {
        margin: 0 1em;
      }

      &_item {
        padding: 0.5em 1em;
      }

      &__playlist-name {
        padding-right: 0;
      }
    }

    &__footer {
      justify-content: space-evenly;
      border-radius: 0 0 0.5em 0.5em;
      border-top: dashed 1px use-color('white');
    }

    &__button {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px;
      border-radius: 0.5em;
      color: use-color('white');
      transition: all 0.3s;

      &:hover,
      &:active {
        background-color: use-color('gray-light');
      }

      &-content {
        display: flex;
        align-items: center;
        height: 20px;
        padding-left: 30px;
        background-image: url('../../assets/icons/save-playlist.svg');
        background-repeat: no-repeat;
        background-size: 20px 18px;
        background-position: 0 center;
      }

      &_disabled {
        opacity: 0.5;
        cursor: default;

        &:hover,
        &:active {
          background-color: use-color('gray');
        }
      }
    }

    &__footer &__button {
      margin-left: 8px;

      &-content {
        background-image: url('../../assets/icons/delete-bin-white.svg');
        background-size: 20px;
      }
    }

    &__order {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      height: 80px;
    }

    &__order-button {
      width: 20px;
      height: 20px;
      background-image: url('../../assets/icons/top-arrow-light.svg');
      background-repeat: no-repeat;
      background-position: center;
      transition: all 0.3s;

      &[disabled] {
        opacity: 0.6;
      }

      &:not([disabled]):hover,
      &:not([disabled]):active {
        opacity: 0.9;
      }

      &.bottom {
        transform: rotate(180deg);
      }
    }

    &__content-wrapper {
      display: flex;
      align-items: center;
      width: 100%;
      height: 30px;
      padding-left: 20px;
      background-color: use-color('light-dim');
    }

    .table {
      border-collapse: separate;

      &__wrapper {
        border-radius: 0;
        /* stylelint-disable-next-line declaration-no-important */
        border-top: dashed 1px use-color('white') !important;
        background-color: use-color('gray-light');

        .form__checkbox {
          & > label {
            flex-direction: column;
            justify-content: center;
          }
        }
      }

      &__head {
        tr > th {
          min-width: auto;
          padding: 0.75em 1em;
          background-color: use-color('gray');
          border-right: solid 2px use-color('white');

          &:nth-child(1),
          &:nth-child(2),
          &:nth-child(3),
          &:last-child {
            border-right: none;
          }

          &:nth-child(2) {
            width: 6em;
          }
        }
      }

      &__body {
        tr {
          background-color: use-color('gray-light');
          color: use-color('light-dim');

          & > td {
            border-top: 1px dashed use-color('white');

            &:nth-child(1) {
              width: 50px;
              text-align: center;
              font-weight: 500;
              font-size: 14px;
              line-height: 16px;
              color: use-color('light-dim');
            }

            &:nth-child(3) {
              .form__checkbox {
                margin: 0;
              }
            }

            &:nth-child(3),
            &:nth-child(4) {
              min-width: 10px;
              width: 40px;
            }

            &:nth-child(4),
            &:nth-child(5),
            &:nth-child(6),
            &:nth-child(7) {
              padding: 0;
              color: use-color('dark');
            }

            &:nth-child(5) {
              min-width: 200px;
              width: 500px;
            }
          }
        }

        .media-list__item-preview {
          margin: 0;

          &_ext {
            display: none;
          }
        }
      }
    }

    &_ext {
      display: block;
      width: 37px;
      padding: 6px;
      border-radius: 3px;
      background-color: use-color('green');
      color: use-color('white');
      font-size: 12px;
      font-weight: 700;
      text-transform: uppercase;
    }
  }
}
