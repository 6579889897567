@import '../../../styles/variables.scss';

.confirmation {
  &__layout {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 101;
    width: 100%;
    height: 100vh;
    background-color: rgba(205, 209, 223, 0.7);
  }

  &__window {
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 400px;
    height: 200px;
    padding: 0 50px;
    background: use-color('gray');
    border-radius: 10px;
    font-size: 14px;
    line-height: 16px;
    transform: translate(-50%, -70%);
  }

  &__text {
    display: flex;
    align-items: center;
    min-height: 30px;
    padding-left: 45px;
    background-image: url('../../../assets/icons/warning.svg');
    background-repeat: no-repeat;
    color: use-color('white');
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
  }

  &__button {
    width: 140px;
    height: 36px;
    border-radius: 6px;
    text-transform: uppercase;
    color: use-color('white');

    &--confirm {
      background-color: use-color('dark-green');
    }

    &--cancel {
      background-color: use-color('red');
    }
  }
}
