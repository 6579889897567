@import './variables.scss';

.layout {
  &__flex {
    display: flex;

    &-wrap {
      flex-wrap: wrap;
    }

    &-column {
      flex-flow: column;
    }

    &-row {
      flex-flow: row;
    }
  }
}
