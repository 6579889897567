@import '../../styles/variables.scss';

.folder {
  &__layout {
    position: relative;
    cursor: pointer;
  }

  &__item {
    display: grid;
    padding: 20px 20px 10px;
    text-align: center;
    font-size: 14px;
    grid-template-columns: auto;
    grid-template-rows: 74px 54px 32px;
    justify-items: center;
  }

  &__icon {
    width: 100px;
    height: 74px;
    background-image: url('../../assets/icons/library-folder.svg');
    background-position: top center;
    background-repeat: no-repeat;
  }

  &__name {
    width: 92px;
    height: 30px;
    margin-top: 13px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__textarea {
    display: inline-block;
    width: 100px;
    height: 21px;
    padding: 5px;
    margin-top: 13px;
    background-color: use-color('white');
    border: solid 2px use-color('green');
    border-radius: 3px;
    resize: none;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__delete {
    margin-top: 10px;
  }

  &__menu {
    position: absolute;
    z-index: 1;
    top: 100px;
    left: 100px;
    width: 227px;
    height: 74px;
    background: use-color('light');
    border-radius: 5px;
  }

  &__action {
    display: flex;
    align-items: center;
    height: 37px;
    padding-left: 10px;
    font-size: 14px;
    cursor: pointer;

    &:hover,
    &:active {
      background-color: use-color('light-dim');
    }
  }

  /* stylelint-disable order/properties-order */
  &__checkbox {
    appearance: none;
    width: 20px;
    height: 20px;
    background-color: use-color('white');
    border: 3px solid use-color('green');
    border-radius: 3px;
    cursor: pointer;

    &:checked {
      background-image: url('../../assets/icons/check-green.svg');
      background-size: 14px;
      background-repeat: no-repeat;
      background-position: center;

      &:hover {
        background-color: use-color('green');
        background-image: url('../../assets/icons/check-hover.svg');
      }
    }
  }
  /* stylelint-enable order/properties-order */
}
