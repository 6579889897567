@import '../../styles/variables.scss';

.menu {
  &__wrapper {
    padding: 3em 0;
    overflow-y: auto;

    @include landscape {
      height: 85%;
    }
  }

  &__item {
    display: grid;
    align-items: center;
    width: 100%;
    padding: 0;
    background-color: use-color('dark');
    border-bottom: 1px dashed use-color('gray');
    transition: all 0.3s;
    grid-template-columns: 65px 200px 35px;

    &:not(&-active) {
      &:hover,
      &:focus {
        background-color: use-color('dark-light');
      }
    }

    &__wrapper {
      overflow: hidden;
      transition: all 0.3s;
    }

    &__arrow {
      width: 24px;
      height: 24px;
      background-image: url('../../assets/icons/gray-arrow.svg');
      background-repeat: no-repeat;
      background-position: center right;
      background-color: transparent;

      &-mock {
        background: none;
      }
    }

    &__list {
      max-height: 0;
      transition: all 0.3s;

      &-item {
        display: block;
        padding: 13px 0 13px 74px;
        background-color: use-color('gray');
        text-align: left;
        line-height: 14px;
        font-size: 12px;
        transition: background-color 0.3s;

        &--active {
          background-color: use-color('dark-light');
          background-image: url('../../assets/icons/sub-menu-active.svg');
          background-repeat: no-repeat;
          background-position-x: 65px;
          background-position-y: center;
        }

        &:hover,
        &:active {
          background-color: use-color('dark-light');
        }

        &:not(:last-child) {
          position: relative;

          &::after {
            content: ' ';
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            border-bottom: 1px dashed use-color('dark');
          }
        }

        @include tablet {
          padding: 0.5em;
          font-size: 0.75em;
        }

        @include mobile {
          padding: 0.5em 3em;
          font-size: 0.65em;
        }
      }
    }

    &-no-submenu &__list {
      display: none;
    }

    &-appear &__arrow {
      background-image: url('../../assets/icons/gray-arrow-up.svg');
    }

    &-active &__arrow:not(&__arrow-mock) {
      background-image: url('../../assets/icons/white-arrow-up.svg');
    }

    &-active:not(&-appear) &__arrow:not(&__arrow-mock) {
      background-image: url('../../assets/icons/white-arrow.svg');
    }

    &-active &__arrow-mock {
      background: none;
    }

    &-active,
    &-active:hover {
      background-color: use-color('green');
      border-bottom: none;
    }

    &__icon {
      display: inline-block;
      width: 21.8px;
      height: 20px;
      margin-left: 19px;
      background-repeat: no-repeat;
      background-size: contain;
    }

    &__title {
      text-align: start;
      line-height: 16px;
      font-size: 14px;

      @include tablet {
        font-size: 0.85em;
      }
      @include mobile {
        font-size: 0.75em;
      }
    }
  }
}
