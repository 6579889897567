@import '../../styles/variables.scss';

.main {
  display: grid;
  grid-template-columns: auto minmax(0, 1fr);
  grid-template-rows: $header-height 1fr;
  grid-template-areas:
    'sidebar navbar'
    'sidebar content';

  &__demobar {
    grid-area: demobar;
  }

  &__sidebar {
    grid-area: sidebar;
  }

  &__navbar {
    grid-area: navbar;
  }

  &__content {
    grid-area: content;
  }

  &--with-demobar {
    grid-template-rows: $demobar-height $header-height 1fr;
    grid-template-areas:
    'demobar demobar'
    'sidebar navbar'
    'sidebar content';
  }
}
