@import 'chartist/dist/scss/chartist.scss';
@import '../../styles/chartist-settings.scss';

@import '../../styles/variables.scss';

.graphics {
  &__wrapper {
    .ct-series-a {
      .ct {
        &-line {
          stroke: use-color('green');
          stroke-width: 3px;
        }

        &-point {
          stroke: use-color('dark-green');
        }

        &-area {
          fill: use-color('green');
        }
      }
    }
  }
}
