@import '../../styles/variables.scss';

.drag-and-drop__file_uploader {
  align-items: center;
  align-content: stretch;
  width: 360px;
  height: 230px;
  overflow-y: auto;
  border: dashed 2px use-color('white');
  text-align: center;
  justify-items: stretch;

  &_input {
    display: none;
  }

  &-loading,
  &-result {
    padding: 3em 0;

    @include tablet {
      padding: 2em 0;
    }
    @include mobile {
      padding: 0;
    }
  }

  @include tablet {
    width: 18em;
    height: 14em;
  }

  @include mobile {
    width: 14em;
    height: 9em;
  }

  &__wrapper {
    position: relative;
    justify-content: center;
    align-items: center;
    width: 550px;
    height: 290px;
    margin: 0 auto;
    background: url(../../assets/images/file-upload-bg.svg) no-repeat center;
    background-size: contain;
    color: use-color('white');

    @include tablet {
      width: 32em;
      min-height: 16em;

      .link-underline {
        font-size: 0.85em;
      }
    }
    @include mobile {
      width: 20em;
      min-height: 12em;

      .link-underline {
        font-size: 0.75em;
      }
    }
  }

  &-text {
    width: 330px;
    margin-bottom: 12px;
    font-size: 16px;

    @include tablet {
      font-size: 0.85em;
    }
    @include mobile {
      font-size: 0.75em;
    }
  }

  &_formats {
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    width: 12em;
    margin-bottom: 12px;
    justify-items: center;

    @include mobile {
      width: 8em;
    }

    &_format {
      display: inline-block;
      width: 2.8em;
      padding: 3px 0;
      margin: 0.15em;
      border-radius: 3px;
      background-color: use-color('green');
      font-size: 12px;
      font-weight: 600;
      text-align: center;
      text-transform: uppercase;

      @include mobile {
        width: 2.4em;
        font-size: 0.65em;
      }
    }
  }

  &__requirements {
    padding: 5px;

    &-button {
      all: unset;
      /* stylelint-disable-next-line */
      margin-bottom: 12px;
      font-size: 14px;
    }

    &-name {
      padding: 5px 0;
    }

    &-element {
      overflow: visible;
      padding: 5px 0 5px 10px;
    }
  }

  &__progress {
    &-bar {
      position: relative;
      width: 70%;
      height: 0.5em;
      border-radius: 4px;
      background-color: use-color(gray-light);

      &-active {
        height: 100%;
        border-radius: 4px;
        background-color: use-color('green');
      }
    }

    &-text {
      margin-top: 15px;
      font-size: 16px;
      font-weight: 700;
    }
  }

  &-icon {
    height: 30px;

    &-xm {
      margin: 15px 0 12px;
    }
  }

  &__result {
    font-size: 14px;
    word-break: breeak-word;
    line-height: 16px;

    b {
      display: inline-block;
      max-width: 90px;
      margin: 0 3px;
      vertical-align: middle;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 16px;
      font-weight: 600;
      text-shadow: 0 2px 0 darken(use-color(dark), 10%);
    }
  }

  &-loading &_formats,
  &-loading .link-underline {
    display: none;
  }
}
