@import '../../styles/variables.scss';

.error {
  &__wrapper {
    align-self: center;
    justify-content: space-between;
    width: 900px;
    height: 600px;
    padding: 175px 130px;
    margin: 0 auto;
    border: 1px solid use-color('gray-light');
    border-radius: 25px;
    background-color: use-color('white');

    @include tablet {
      width: 80%;
      padding: 4em;
    }
    @include mobile {
      justify-content: center;
      align-self: auto;
      width: 100%;
      height: 100%;
      padding: 1em;
      margin: 0;
      border: 0;
      border-radius: 0;
    }
  }

  &__content {
    flex-grow: 1;
  }

  &__message {
    color: use-color('gray-light');
  }

  &__heading {
    margin-bottom: 27px;
    font-size: 64px;
    font-weight: 900;
    color: use-color('red');

    @include mobile {
      font-size: 2em;
    }
  }

  &__message {
    margin-bottom: 7px;
    font-size: 24px;

    &:last-child {
      font-size: 14px;

      @include mobile {
        font-size: 1.5em;
      }
    }

    @include mobile {
      font-size: 0.75em;
    }
  }

  &__icon {
    width: 127px;
    height: 117px;
    background-image: url('../../assets/icons/error-page.svg');
  }

  &__reload,
  &__link {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 50px;
    background: use-color('dark');
    border-radius: 5px;
    text-transform: uppercase;
  }

  &__reload {
    font-size: 24px;
  }
}
