@import 'styles/variables.scss';

.pagination {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: 30px;
  margin: 0 auto;
  border-radius: 1em;
  background-color: use-color('white');
  font-size: 18px;

  @include tablet {
    width: 70%;
  }
  @include mobile {
    width: 100%;
    margin: 0;
  }

  &__wrapper {
    margin: 1em auto;
  }

  &__pages {
    display: flex;
    align-items: center;
    height: 30px;
  }

  &__item {
    padding: 4px;
  }

  &__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 22px;
    height: 22px;
    border: 0;
    border-radius: 50%;
    background-color: transparent;
    transition: all 0.3s;
    appearance: none;

    &:hover,
    &:active {
      background-color: use-color(gray-light);
      color: use-color('white');
    }

    &--active {
      background-color: use-color('dark');
      color: use-color('white');
    }

    &-prev,
    &-next {
      width: 26px;
      height: 26px;
      background-image: url('../../assets/icons/circle-arrow.svg');
      background-position: center;
      background-repeat: no-repeat;
      font-size: 0;
      transform: rotate(0deg);
    }

    &-prev {
      transform: rotate(180deg);

      &--disabled {
        background-image: url('../../assets/icons/circle-arrow-disabled.svg');
        cursor: default;
      }
    }

    &-next {
      &--disabled {
        background-image: url('../../assets/icons/circle-arrow-disabled.svg');
        cursor: default;
      }
    }

    &-prev,
    &-next {
      &:hover,
      &:active {
        background-color: unset;
      }
    }

    &-first,
    &-last {
      width: 22px;
      height: 22px;
      background-image: url('../../assets/icons/double-circle-arrow.svg');
      font-size: 0;
    }

    &-first {
      transform: rotate(180deg);

      &--disabled {
        background-image: url('../../assets/icons/double-circle-arrow-disabled.svg');
      }
    }

    &-last {
      &--disabled {
        background-image: url('../../assets/icons/double-circle-arrow-disabled.svg');
      }
    }
  }
}
