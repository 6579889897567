@import '../../styles/variables.scss';

.sub-options {
  &__layout {
    display: flex;
    justify-content: space-between;
    padding: 100px 50px 0;
    background: use-color('white');
    background-image: url('../../assets/brand/logo-light.svg');
    background-repeat: no-repeat;
    background-position: center 30px;
    border-radius: 25px;
  }

  &__option {
    width: 330px;
    height: 450px;
    border-radius: 10px;
    background-color: use-color('light');
  }

  &__title {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    background-color: use-color('light-dim');
    border-radius: 10px 10px 0 0;
    font-weight: 900;
    font-size: 24px;
    text-transform: uppercase;

    &--pro {
      background-color: use-color('green');
      color: use-color('white');
    }
  }

  &__price {
    width: fit-content;
    margin: 28px auto;
    font-size: 64px;

    > span {
      font-size: 32px;
      font-weight: 900;
    }
  }

  &__button {
    width: 260px;
    height: 50px;
    margin: 0 auto;
    border-radius: 10px;
    font-size: 24px;
  }

  &__positions {
    margin-top: 25px;
  }

  &__position {
    padding: 10px 0 0 30px;
    margin: 12px 35px;
    background-image: url('../../assets/icons/check-box.svg');
    background-repeat: no-repeat;
    background-size: 20px;
    text-align: left;
    font-size: 14px;
  }
}
