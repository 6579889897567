@import './fonts.scss';

$palette-colors: (
  'main': #f60,
  'dark': #474f64,
  'dark-light': #535c73,
  'gray': #697185,
  'gray-light': #888fa3,
  'light-dim': #cdd1df,
  'light': #eef0f6,
  'white': #fff,
  'green': #32c832,
  'dark-green': #00a800,
  'red': #e41146,
);

$inactive-opacity: 0.4;
$hover-opacity: 0.8;
$main-transition: all 0.2s ease-in-out;

$tablet-width: 768px;
$desktop-width: 1024px;
$max-desktop-width: 1040px;
$content-width: 1200px;
$max-content-width: 1440px;

$demobar-height: 50px;
$header-height: 55px;

@function use-color($key: 'dark') {
  @return map-get($palette-colors, $key);
}

@mixin mobile {
  @media (max-width: #{$tablet-width - 1px}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

@mixin landscape {
  @media (orientation: landscape) {
    @content;
  }
}

@mixin backdrop-filter($val) {
  -webkit-backdrop-filter: $val;
  backdrop-filter: $val;
}
