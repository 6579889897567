@import '../../styles/variables.scss';

.navigation {
  &__wrapper {
    position: relative;
    z-index: 1100;
    align-items: center;
    padding: 3px 33px;
    background-color: use-color('gray');
    box-shadow: 0 5px 25px 1px rgba(0, 0, 0, 0.1);
    color: use-color('white');

    &_left {
      justify-content: flex-start;
    }

    &-right {
      align-items: center;
      display: flex;
      justify-content: flex-end;
    }
  }

  &__profile {
    &-block {
      position: relative;
    }

    &-button {
      width: 18px;
      height: 20px;
      margin-left: 12px;
      background-image: url('../../assets/icons/profile.svg');
    }

    &-menu {
      position: absolute;
      right: 0;
      top: 28px;
      z-index: 1;
      width: 200px;
      background: use-color('dark');
      border: 1px solid use-color('gray');
      border-radius: 5px;
    }
  }

  &__logout {
    width: 24px;
    height: 18px;
    margin-left: 19px;
    cursor: pointer;
    background-image: url('../../assets/icons/profile-logout.svg');
    background-repeat: no-repeat;
    background-size: contain;
  }

  &__search {
    &__form {
      position: relative;
      flex-basis: 40%;
      flex-direction: row;
      visibility: hidden;

      > .form__field {
        width: 100%;
        margin: 0;
      }
    }

    &__input {
      padding: 1.25em;
      padding-right: 3em;
      margin: 0.25em;
      border: solid 1px use-color(light-dim);
      border-radius: 2em;
      background-color: use-color('white');
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &__submit {
      position: absolute;
      right: 0;
      padding: 0.85em;
      border: 0;
      outline: 0;
      background-color: transparent;
      appearance: none;
    }

    &__icon {
      height: 1.5em;
    }
  }

  &__balance {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 120px;
    height: 30px;
    padding: 0 8px;
    margin-right: 10px;
    border: 1px solid use-color('white');
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
  }

  &__add-money {
    display: flex;
    column-gap: 5px;
    align-items: center;
    justify-content: space-between;
    width: unset;
    height: 30px;
    padding: 0 6px;
    background: linear-gradient(
        use-color('dark-green'),
        use-color('dark-green')
    );
    border: 2px solid use-color('dark-green');
    font-size: 12px;

    &:hover {
      background: linear-gradient(
          180deg,
          use-color('dark-green') 0%,
          use-color('green') 100%
      );
    }

    &:active {
      background: linear-gradient(
          180deg,
          use-color('dark-green') 0%,
          use-color('green') 100%
      );
      border: 2px solid use-color('dark-green');
    }
  }

  &__monitors {
    &-list {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 120px;
      height: 30px;
      padding: 0 10px;
      margin-left: 10px;
      border: 1px solid use-color('white');
      border-radius: 5px;
      font-size: 12px;
    }

    &-group {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      height: 20px;
    }

    &-light {
      width: 5px;
      height: 5px;

      &--red {
        background-color: use-color('red');
      }

      &--green {
        background-color: use-color('dark-green');
      }

      &--gray {
        background-color: use-color('light-dim');
      }
    }
  }

  &__email,
  &__languages {
    display: flex;
    align-items: center;
    max-width: 300px;
    height: 3em;
    padding: 0.85em 0;
    border-radius: 1em;
  }

  &__languages {
    padding: 0 0 0 20px;
  }

  &__menu {
    align-items: center;
    display: flex;
    column-gap: 10px;

    &-item {
      align-items: center;
      display: flex;
    }

    &-icon {
      display: inline-block;
      margin: -2px 6px 0 0;
    }
  }
}

.profile-menu {
  &__item {
    display: flex;
    align-items: center;
    justify-content: left;
    height: 40px;
    padding-left: 40px;
    border-bottom: 1px dashed use-color('gray');
    background-repeat: no-repeat;
    background-position: 10px 13px;
    font-size: 14px;
    transition: all 0.3s;

    &:last-child {
      border-bottom: none;
    }

    &:hover,
    &:focus {
      background-color: use-color('dark-light');
    }
  }
}

.react-select {
  &__container {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 12px;
  }

  &__control {
    width: 60px;
    height: 30px;
    background: use-color('dark-green');
    border-radius: 5px;
    cursor: pointer;
  }

  &__value-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding-left: 6px;
    border-radius: 5px;
    background-color: use-color('dark-green');
    background-image: url('../../assets/icons/select-arrow-chosen.svg');
    background-repeat: no-repeat;
    background-position: 8px 14px;
    color: use-color('white');
  }

  &__menu-list {
    padding: 0;
  }

  &__menu {
    position: absolute;
    z-index: 1;
    overflow: hidden;
    border-radius: 5px;
    cursor: pointer;
  }

  &__option {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 30px;
    padding-left: 6px;
    background-color: use-color('light-dim');
    background-image: url('../../assets/icons/select-arrow.svg');
    background-repeat: no-repeat;
    background-position: 8px 11px;
    border-bottom: 1px dashed use-color('gray');
    color: use-color('gray');

    &:last-child {
      border-bottom: none;
    }

    &:hover,
    &:active {
      background-color: use-color('dark-green');
      color: use-color('white');
    }
  }
}
