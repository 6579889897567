@import '../../styles/variables.scss';

.sidebar {
  &__wrapper {
    position: relative;
    z-index: 1100;
    flex: 0 0 auto;
    width: 300px;
    height: 100%;
    padding: 1.7em 0;
    overflow-x: hidden;
    background-color: use-color(dark);
    text-align: center;
    transition: all 0.2s ease;

    &--closed {
      width: 120px;
      transition: all 0.2s ease;

      .menu__item {
        &__title {
          display: none;
        }

        &__list-item {
          overflow: hidden;
          padding: 13px;
          text-align: center;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__logo {
    width: 149px;
    height: 35px;
    margin-right: 75px;
    background-image: url('../../assets/brand/logo-color.svg');
    transition: all 0.2s ease;

    &--closed {
      width: 38px;
      margin-right: 20px;
      background-image: url('../../assets/brand/logo-mini.svg');
    }
  }

  &__version {
    position: absolute;
    right: 75px;
    top: 62px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 20px;
    background-color: use-color('green');
    border-radius: 4px;
    font-weight: 900;
    font-size: 12px;
    text-transform: uppercase;
    color: use-color('white');

    &--demo {
      background-color: use-color('red');
    }

    &--closed {
      right: 19px;
    }
  }

  &__burger {
    box-sizing: content-box;
    width: 22px;
    height: 19px;
    padding: 8px 6px;
    margin-left: 13px;
    background-image: url('../../assets/icons/burger-menu.svg');
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 2px;
    transition: all 0.15s ease;

    &:hover,
    &:active,
    &--closed {
      margin-left: 13px;
      background-color: use-color('dark-light');
    }
  }
}
