@import './variables.scss';

#root {
  width: 100%;
  height: 100%;
  background-color: use-color('light');
}

.app-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 75px;
  background: url(../assets/brand/logo.svg) center no-repeat use-color('dark');
  background-size: auto 60%;

  &.color {
    background-image: url(../assets/brand/logo-color.svg);
  }
}

a {
  text-decoration: none;
  color: use-color('white');

  &.link {
    color: use-color('dark');

    &:hover,
    &:active {
      color: darken(use-color('dark'), 50%);
    }
  }
}

.link-underline {
  text-decoration: underline;
}
