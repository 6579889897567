@import './reset.scss';
@import './variables.scss';
@import './fonts.scss';

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html,
body {
  height: 100%;
  padding: 0;
  margin: 0;
  overflow: hidden;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
}

html,
body,
a,
img {
  -webkit-user-drag: none;
}

body {
  overflow: hidden;
  color: use-color('dark');
  @include roboto;
  font-size: 16px;
  font-weight: 300;

  ::-webkit-scrollbar-button {
    width: 0.75em;
  }

  ::-webkit-scrollbar-track {
    background-color: use-color('gray-light');
    border-radius: 1em;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 1em;
    background-color: use-color('green');
  }

  ::-webkit-scrollbar {
    width: 0.75em;
    height: 0.75em;
  }
}

.clear {
  height: 1px;
  margin-top: -1px;
  overflow: hidden;
  clear: both;
}

label,
input[type='button'],
button,
a {
  cursor: pointer;
}

[aria-disabled],
[disabled] {
  cursor: not-allowed;
  opacity: 0.6;
}

input {
  border-radius: 0;
  appearance: none;
  /* stylelint-disable-next-line */
  -webkit-border-radius: 0;
  /* stylelint-disable-next-line */
  -webkit-appearance: none;
}

ul {
  list-style-type: none;
}

li {
  overflow: hidden;
  list-style-position: outside;
}

@include font-face('Roboto', 'Roboto-Light', 300);
@include font-face('Roboto', 'Roboto', 400);
@include font-face('Roboto', 'Roboto-SemiBold', 500);
@include font-face('Roboto', 'Roboto-Bold', 900);
