@import 'chartist/dist/scss/chartist.scss';
@import '../../styles/chartist-settings.scss';

@import '../../styles/variables.scss';

.current-user {
  &__section {
    background-color: use-color('white');
    border-radius: 10px;

    &--left {
      display: flex;
      flex-direction: column;
    }

    &--playlists {
      margin-top: 84px;
    }
  }

  &__section-top {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 62px;
    padding: 0 18px;
    border-bottom: 1px dashed use-color('gray');
  }

  &__title {
    padding-left: 36px;
    background-repeat: no-repeat;
    background-position: left center;
    font-size: 22px;
    line-height: 26px;
  }

  &__title-info {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 200px;
    text-align: right;
    text-transform: lowercase;
    font-size: 12px;
    line-height: 14px;
  }

  &__title-info-value {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    padding: 0 10px;
    margin-left: 10px;
    background: use-color('dark');
    border-radius: 5px;
    font-size: 22px;
    line-height: 26px;
    color: use-color('white');
  }

  &__devices,
  &__playlists {
    margin-top: 18px;
  }

  &__devices-option,
  &__playlists-option {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 36px;
  }

  &__devices-label,
  &__playlists-label {
    flex-shrink: 0;
    font-size: 16px;
    line-height: 18px;
    color: use-color('gray-light');
  }

  &__devices-line,
  &__playlists-line {
    width: 100%;
    margin: 0 24px;
    border-bottom: 1px dashed use-color('dark');
  }

  &__devices-value,
  &__playlists-value {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 72px;
    height: 36px;
    border-radius: 5px;
    font-size: 32px;
    color: use-color('white');
  }

  &__devices-value {
    &--online {
      background-color: use-color('green');
    }

    &--offline {
      background-color: use-color('gray-light');
    }

    &--drafts {
      background-color: use-color('light');
      color: use-color('gray-light');
    }
  }

  &__playlists-value {
    &--on {
      background-color: use-color('green');
    }

    &--off {
      background-color: use-color('gray-light');
    }
  }

  &__add-create-button {
    width: 144px;
    height: 36px;
    background-color: use-color('dark-green');
    border-radius: 3px;
    font-size: 14px;
    line-height: 16px;
    color: use-color('white');
  }

  &__info {
    padding: 0 18px;
  }

  &__info-field {
    margin: 18px 0 0 8px;
    font-size: 12px;
    text-transform: lowercase;
  }

  &__info-value {
    height: 36px;
    padding: 10px;
    margin-top: 5px;
    background: use-color('light');
    border-radius: 5px;
    font-size: 16px;
    color: use-color('gray');
  }

  &__disk {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 40px;
  }

  &__disk-options {
    position: absolute;
    z-index: 10;
    top: 13px;
    right: 18px;
  }

  &__disk-options-button {
    width: 216px;
    height: 36px;
    padding: 8px 10px;
    background-color: use-color('light');
    border: 1px solid use-color('light');
    border-radius: 5px;
    background-image: url('../../assets/icons/disk-options-arrow-down.svg');
    background-repeat: no-repeat;
    background-size: 22px;
    font-size: 16px;
    line-height: 18px;
    text-align: right;
    color: use-color('gray');
    background-position-x: 8px;
    background-position-y: center;

    &--opened {
      background-color: use-color('white');
      border: 1px solid use-color('gray-light');
      border-bottom: none;
      border-radius: 5px 5px 0 0;
      background-image: url('../../assets/icons/disk-options-arrow-up.svg');
    }
  }

  &__disk-options-list {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-end;
    width: 216px;
    height: 180px;
    padding: 0 10px;
    background-color: use-color('white');
    border: 1px solid use-color('gray-light');
    border-top: none;
    border-radius: 0 0 5px 5px;
    font-size: 16px;
    line-height: 18px;
    color: use-color('gray');
  }

  &__disk-option {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &__disk-option-checkbox {
    width: 22px;
    height: 22px;
  }

  &__pie {
    position: relative;

    &--playing {
      stroke: use-color('red');
    }

    &--not-playing {
      stroke: use-color('gray');
    }

    &--used {
      stroke: use-color('gray-light');
    }

    &--free {
      stroke: #dbe0f2;
    }
  }

  &__free-space {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 16px;
    text-align: center;
    color: use-color('gray');
    transform: translate(-50%, -50%);
  }

  &__free-space-value {
    margin-top: 18px;
    font-weight: 400;
    text-align: center;
  }

  &__space-field {
    display: flex;
    align-items: center;
    margin-bottom: 28px;
    font-size: 12px;

    &--total {
      font-size: 16px;
    }
  }

  &__space-value {
    display: inline-block;
    padding-left: 5px;
    font-weight: 400;

    &--total {
      font-size: 16px;
    }
  }

  &__space-label {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 18px;

    &--total {
      background-color: #dbe0f2;
    }

    &--busy {
      background-color: use-color('gray-light');
    }

    &--is-used {
      background-color: use-color('red');
    }

    &--is-not-used {
      background-color: use-color('gray');
    }
  }
}

.content {
  &#userProfile,
  &#metrics {
    align-items: flex-start;
    display: grid;
    gap: 10px;
    grid-template-rows: auto 1fr;

    .page-plate {
      &.details {
        grid-row: span 2;
      }
    }
  }

  &#userProfile {
    grid-template-columns: repeat(2, 1fr);
    width: 960px;

    .page-plate {
      &__input {
        width: 220px;
      }
    }
  }

  &#metrics {
    grid-template-columns: repeat(2, 576px);
  }
}
