@import '../../styles/variables.scss';

.big-input__form {
  position: relative;
  flex-direction: row;
  align-items: center;
  padding: 0;
  padding-bottom: 20px;
  font-size: 1.25em;

  > .form__field {
    margin: 0;
  }

  &_hint {
    font-size: 18px;
  }

  &__input {
    width: 400px;
    height: 60px;
    border: solid 2px use-color('green');
    border-radius: 10px;
    background-color: use-color('white');
    color: use-color('dark');
    font-size: 2em;
    text-align: center;
    text-indent: 1em;

    &:focus,
    &:active {
      border: solid 2px use-color('green');
    }

    @include tablet {
      font-size: 1.5em;
    }

    @include mobile {
      font-size: 1.5em;
    }

    &-icon {
      position: absolute;
      top: 10px;
      left: 10px;
      height: 40px;

      @include tablet {
        top: 0.25em;
        left: 0.25em;
        height: 2.5em;
      }
      @include mobile {
        top: 0.25em;
        left: 0.25em;
        height: 2.5em;
      }
    }
  }

  &__submit-btn {
    margin: 0 20px;
    width: 60px;
    height: 60px;
    padding: 0.75em;
    background-color: use-color('green');
    border-radius: 0.5em;

    @include tablet {
      padding: 0.5em;
      flex-basis: 8%;
    }
    @include mobile {
      padding: 0.5em;
      flex-basis: 8%;
    }

    &-icon {
      height: 1.5em;

      &.rot-90 {
        transform: rotate(-90deg);
      }

      @include mobile {
        height: 1em;
      }
    }
  }
}

.big-input {
  &__playlist-submit {
    width: 80px;
    height: 80px;
  }

  &__playlist-input {
    font-size: 0.9em;

    &::placeholder {
      color: use-color('green');
    }
  }
}
