@import '../../styles/variables.scss';

.preloader {
  &__wrapper {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: use-color('light-dim');
  }

  &-transition {
    &-enter {
      transform: scale(1.5);
      opacity: 0;
      transition: all 0.3s ease-out;
    }

    &-enter-active {
      transform: scale(1);
      opacity: 1;
    }

    &-exit {
      transform: scale(1);
      opacity: 1;
    }

    &-exit-active {
      transform: scale(1.5);
      opacity: 0;
      transition: all 0.3s ease-in;
    }
  }
}
