@import '../../styles/variables.scss';

.applications {
  &__categories {
    flex-direction: row;
    font-size: 14px;
    line-height: 16px;
    padding: 0.5em 0.125em;

    &-item {
      &:not(:first-of-type) {
        margin-left: 20px;
      }
    }

    .form__checkbox-mask {
      width: 20px;
      height: 20px;
      border-width: 3px;
      background-size: 14px 10px;
    }
  }

  &__table {
    margin-top: 10px;

    td {
      span {
        overflow-wrap: break-word;
        line-height: 1.25em;
      }
    }

    .table__head {
      tr {
        th {
          &:nth-child(1) {
            min-width: 80px;
            padding-left: 20px;
          }

          &:nth-child(2),
          &:nth-child(6),
          &:nth-child(8) {
            min-width: 120px;
          }

          &[data-accessor='playlist'] {
            min-width: 160px;
          }

          &[data-accessor='monitor'] {
            min-width: 230px;
          }

          &[data-accessor='status'] {
            min-width: 200px;
          }

          &[data-accessor='period'] {
            min-width: 260px;
          }

          &[data-accessor='address'] {
            min-width: 310px;
          }
        }
      }
    }
  }

  &__monitor {
    display: flex;
    align-items: center;
    height: 24px;
    vertical-align: middle;
    padding-left: 34px;
    background-image: url('../../assets/icons/monitor-off.svg');
    background-size: 24px 22px;
    background-repeat: no-repeat;
    background-position: left center;
  }

  &__playlist {
    display: flex;
    align-items: center;
    height: 24px;
    vertical-align: middle;
    padding-left: 40px;
    background-image: url('../../assets/icons/playlist-off.svg');
    background-size: 24px 22px;
    background-repeat: no-repeat;
    background-position: left center;
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
  }

  &__status-ok {
    color: use-color('green');
  }

  &__confirm {
    width: 20px;
    height: 20px;
    margin-right: 23px;
    background-image: url('../../assets/icons/playlist-confirm.svg');
  }

  &__deny {
    width: 21px;
    height: 21px;
    background-image: url('../../assets/icons/playlist-cancel.svg');
  }

  &__window {
    @include backdrop-filter(blur(3px));
    position: absolute;
    top: 0;
    left: 0;
    z-index: 101;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: calc(100% - 50px);
    background-color: rgba(71, 79, 100, 0.9);
  }

  &__player-layout {
    position: relative;
    width: 996px;
  }

  &__player {
    height: 560px;
    border-radius: 10px;
    background-color: use-color('dark');
  }

  &__close {
    position: absolute;
    top: 0;
    right: -56px;
    width: 36px;
    height: 36px;
    background-image: url('../../assets/icons/close-button-hollow.svg');
    transition: $main-transition;

    &:hover,
    &:active {
      background-image: url('../../assets/icons/close-button-hollow-hover.svg');
    }
  }

  &__controls-layout {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
  }

  &__info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 350px;
    height: 50px;
    padding: 10px;
    margin-top: 11px;
    background-color: use-color('dark');
    border-radius: 5px;
    font-size: 12px;
    line-height: 14px;
    color: use-color('white');
  }

  &__info-name {
    display: inline-block;
    height: 18px;
    padding: 2px 0 0 34px;
    background-image: url('../../assets/icons/monitor-white.svg');
    background-repeat: no-repeat;
    background-position: left center;
    background-size: 20px 18px;
  }

  &__info-container {
    display: flex;
  }

  &__info-time,
  &__info-price {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 30px;
    background-color: use-color('gray');
    border-radius: 5px;
  }

  &__info-price {
    margin-left: 10px;
  }

  &__controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 240px;
  }

  &__controls-button {
    border-radius: 50%;
    background-color: use-color('dark');
    background-position: center;
    background-repeat: no-repeat;
    font-size: 12px;
    line-height: 14px;
    color: use-color('white');
    transition: background-color 0.2s;

    &:active {
      background-color: #3d4559;
    }
  }

  &__decor {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: use-color('dark');
  }

  &__speed {
    width: 20px;
    height: 20px;
  }

  &__rewind {
    width: 40px;
    height: 40px;
    background-image: url('../../assets/icons/rewind.svg');
  }

  &__forward {
    width: 40px;
    height: 40px;
    background-image: url('../../assets/icons/forward.svg');
  }

  &__play {
    width: 80px;
    height: 80px;
    background-image: url('../../assets/icons/play.svg');
    background-position: 32px center;

    &--on {
      background-image: url('../../assets/icons/pause.svg');
      background-size: 22px;
      background-position: center;
    }
  }

  &__buttons {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    [disabled] {
      background-color: use-color('dark');
      opacity: 0.7;
      cursor: not-allowed;
    }
  }

  &__buttons-approve,
  &__buttons-deny {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 171px;
    height: 50px;
    background-color: use-color('green');
    border-radius: 5px;
    font-size: 18px;
    line-height: 21px;
    color: use-color('white');
    transition: $main-transition;
  }

  &__buttons-approve {
    background-color: use-color('green');
  }

  &__buttons-deny {
    margin-left: 6px;
    background-color: use-color('dark');
  }

  button.applications__buttons {
    &-approve {
      &:active {
        background-color: use-color('dark-green');
      }
    }

    &-deny {
      &:active {
        background-color: #3d4559;
      }
    }
  }
}

.application-form {
  background-color: #f0f0f0;
  border-radius: 3px;
  padding: 0.9em 0;
  text-align: center;

  h3 {
    font-size: 17px;
    margin: 0.5em 0;
  }

  section {
    &:not(&:first-of-type) {
      border-top: dashed 1px use-color(dark);
      padding-top: 12px;
    }
  }

  fieldset {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 1.5em;

    .react-daterange-picker__inputGroup {
      margin: 0;
    }

    &[name='queue'] {
      .application-form__field:not(&:first-child) {
        margin-left: 7px;
      }
    }

    &[name='calendar'] {
      padding: 0;

      .react-date-picker__wrapper,
      .react-daterange-picker__wrapper {
        display: none;
      }
    }
  }

  &__field {
    align-items: center;
    display: flex;
    font-size: 12px;
    min-width: 90px;

    .form__field {
      margin-right: 7px;
    }

    &[data-name='onlyWhen'],
    &[data-name='both'] {
      border: solid 2px;
      border-radius: 2px;
      padding: 2px 4px;

      &::after {
        background-repeat: no-repeat;
        background-position: center;
        background-image: url('../../assets/icons/filter-date-dark.svg');
        background-size: 60%;
        content: '';
        margin-left: 7px;
        height: 23px;
        width: 25px;
      }
    }
  }

  &-popup {
    .popup-fullscreen__container {
      width: unset;
      min-height: 560px;
    }
  }

  .button-primary {
    margin: 0 auto;
    width: unset;
    padding: 0 0.5em;
  }
}
