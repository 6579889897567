@import '../../styles/variables.scss';

.video {
  user-select: none;

  &__output {
    height: calc(100% - 30px);
    width: 100%;
    object-fit: contain;
  }

  &__track {
    position: relative;
    overflow: hidden;
    height: 20px;
    margin-top: 10px;
    background: use-color('gray-light');
    border-radius: 5px;
  }

  &__slider-layout {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
  }

  &__slider {
    position: relative;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 20px;
    background-color: use-color('green');
    border-radius: 5px;
    font-size: 12px;
    line-height: 14px;
    color: use-color('white');
    transition: background-color 0.2s;
    cursor: grab;

    &:active {
      background-color: use-color('dark-green');
      cursor: grabbing;
    }
  }

  &__slider-before,
  &__slider-after {
    position: absolute;
    top: 0;
    width: 30px;
    height: 20px;
    background: linear-gradient(
      90deg,
      use-color('dark') 0%,
      use-color('gray-light') 100%
    );
  }

  &__slider-before {
    left: -15px;
    transform: rotate(180deg);
  }

  &__slider-after {
    right: -15px;
  }

  &.grab & {
    &__slider {
      background-color: use-color('dark-green');
      cursor: grabbing;
    }
  }
}
