.Report {
  background-image: url('statistics.svg');
}

.User {
  background-image: url('profile-gray.svg');
}

.Save {
  background-image: url('save.svg');

  &White {
    background-image: url('save-white.svg');
  }
}

.Devices {
  background-image: url('../../assets/icons/monitors-gray.svg');
  background-position: center center;
  width: calc(var(--icon-size) * 1.33);
}

.Playlists {
  background-image: url('../../assets/icons/current-user-playlists.svg');
}

.Disk {
  background-image: url('../../assets/icons/disk.svg');
}
