@import '../../styles/variables.scss';

.playlists {
  &__wrapper {
    margin-top: 1em;

    .media-list__item {
      &-file-info {
        border: 0;
        background-color: transparent;

        &_duration {
          border: 0;
        }
      }
    }
  }

  &__table {
    .table__wrapper {
      max-height: 78vh;
    }

    .table__head {
      tr {
        th {
          &[data-accessor='checkbox'],
          &[data-accessor='name'],
          &:nth-child(6) {
            min-width: 210px;
          }

          &:nth-child(5),
          &:nth-child(7) {
            min-width: 160px;
          }
        }
      }
    }

    .table__head-container {
      overflow: visible;
    }
  }

  &__filter-layout {
    position: relative;
  }

  &__filter-name {
    width: 22px;
    height: 22px;
    margin-right: 10px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('../../assets/icons/filter-name.svg');

    &:hover {
      background-image: url('../../assets/icons/filter-name-hover.svg');
    }

    &:active {
      background-image: url('../../assets/icons/filter-name-active.svg');
    }
  }

  &__filter-date {
    width: 25px;
    height: 23px;
    margin-right: 10px;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url('../../assets/icons/filter-date.svg');

    &:hover {
      background-image: url('../../assets/icons/filter-date-hover.png');
    }

    &:active {
      background-image: url('../../assets/icons/filter-date-active.png');
    }
  }

  &__filter-status {
    width: 20px;
    height: 18px;
    margin-right: 10px;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url('../../assets/icons/filter-status.svg');

    &:hover {
      background-image: url('../../assets/icons/filter-status-hover.svg');
    }

    &:active {
      background-image: url('../../assets/icons/filter-status-active.svg');
    }
  }

  &__item {
    &__action {
      justify-content: space-evenly;
      width: 100%;
    }
  }

  &__library {
    &__action {
      &_button {
        display: flex;
        flex-flow: row;
        justify-content: space-evenly;
        align-items: center;
        width: 25%;
        padding: 0.5em;
        border-radius: 0.5em;
        color: use-color(dark);
        transition: all 0.3s;

        &:hover,
        &:active {
          background-color: use-color(light-dim);
        }

        img {
          max-height: 1.25em;
          margin-right: 1em;
        }
      }
    }
  }

  &__link-button {
    width: 400px;
    height: 50px;
    border-radius: 5px;
    font-size: 18px;
    line-height: 21px;

    @include tablet {
      padding: 0.5em;
      flex-basis: 8%;
    }
    @include mobile {
      padding: 0.5em;
      flex-basis: 8%;
    }
  }

  &__search-icon {
    height: 35px;
    margin-right: 10px;
    transform: rotate(-90deg);

    @include mobile {
      height: 1em;
    }
  }
}

.map {
  &.monitor__table {
    .table__wrapper {
      max-height: 36vh;
      margin-bottom: 25px;
    }

    .table__head {
      tr {
        th {
          &[data-accessor='checkbox'] {
            min-width: unset;
            width: 50px;
            border-right: 2px solid use-color('white');
          }

          &[data-accessor='name'] {
            min-width: 240px;
          }

          &:nth-child(4),
          &:nth-child(7) {
            min-width: 200px;
          }

          &:nth-child(5),
          &:nth-child(6),
          &:nth-child(8),
          &:nth-child(9) {
            min-width: 160px;
          }
        }
      }
    }

    .table__body {
      tr {
        td:nth-child(1) {
          label {
            display: block;
            width: 25px;
          }
        }

        td:nth-child(2) {
          text-align: left;
        }

        td:nth-child(7) {
          font-size: 0.8em;
        }
      }
    }

    .form__checkbox-mask {
      &::before {
        bottom: 7px;
      }

      &::after {
        bottom: 10px;
      }
    }
  }

  &.playlists__table {
    .table__wrapper {
      min-height: 390px;
      max-height: 36vh;
      margin-top: 25px;
    }

    .table__head {
      tr {
        /* stylelint-disable-next-line no-descending-specificity */
        th {
          &:nth-child(1) {
            min-width: unset;
            width: 50px;
            border-right: 2px solid use-color('white');
          }

          &:nth-child(2),
          &:nth-child(3),
          &:nth-child(6) {
            min-width: 210px;
          }

          &:nth-child(4) {
            min-width: 220px;
          }

          &:nth-child(5),
          &:nth-child(7) {
            min-width: 160px;
          }
        }
      }
    }

    .table__body {
      tr {
        td:nth-child(1) {
          label {
            display: block;
            width: 25px;
          }
        }

        td:nth-child(2) {
          text-align: left;
        }
      }
    }
  }
}
