@import '../../styles/variables.scss';

.user {
  &__icon {
    padding: 0;
    margin-right: 38px;
  }

  &__table {
    .table__head {
      tr {
        th {
          padding-left: 20px;
          text-align: left;

          &:nth-child(1) {
            padding-left: 70px;
          }

          &:nth-child(1),
          &:nth-child(2),
          &:nth-child(3),
          &:nth-child(4),
          &:nth-child(5) {
            min-width: 155px;
          }

          &:nth-child(6) {
            min-width: 120px;
          }

          &:nth-child(7),
          &:nth-child(8) {
            min-width: 140px;

            .table__head-container {
              width: 100px;
            }
          }

          &:nth-child(9) {
            min-width: 105px;
          }
        }
      }
    }

    .table__body {
      tr {
        td {
          text-align: start;

          &:nth-child(1) {
            .text {
              display: flex;
              padding-right: 10px;
              white-space: nowrap;
            }
          }
        }
      }
    }
  }
}
