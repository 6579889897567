@import '../../styles/variables.scss';

.page-image {
  height: 6em;
}

.auth {
  &__field {
    margin-bottom: 45px;
  }

  &__reset-field {
    margin: 30px 0 175px;
  }

  &__button {
    width: 100%;
    background-color: use-color('green');
    font-weight: 500;
    font-size: 18px;
    line-height: 12px;

    &:hover {
      background-color: use-color('dark-green');
    }

    &--margin {
      margin-bottom: 30px;
    }
  }

  &__link {
    display: inline-block;
    margin-top: 16px;
  }

  &__icon {
    margin-bottom: 120px;
  }
}
