@import '../../styles/variables.scss';

.add__edit {
  &__form {
    &__require {
      &_edit {
        margin: 1em 0;
        padding: 1em;
        padding-left: 4em;
        border: solid 1px use-color('green');
        background: url(../../assets/icons/error.svg) 5% center no-repeat;
        background-size: auto 40%;
        border-radius: 0.5em;
        font-weight: 400;
        color: use-color('green');
      }
    }

    &__actions {
      margin: 22px 0 0 auto;

      .button {
        margin: 0 0 0 10px;
      }
    }
  }

  &__photos {
    &__layout {
      flex-basis: 60%;
      align-items: center;
      justify-items: center;
    }

    &__wrapper {
      position: relative;
      align-items: center;
      padding: 0 2em;
      margin-left: 1em;
    }

    &__navigation {
      position: absolute;
      width: 24px;
      height: 24px;
      background: url(../../assets/icons/circle-arrow.svg) center no-repeat;
      background-size: 22px;
      color: transparent;
      transition: all 0.3s;

      &[disabled] {
        opacity: 0.5;
      }

      &-prev {
        left: 0;
        transform: rotate(180deg);

        &:hover,
        &:active {
          transform: rotate(180deg) scale(1.1);
        }
      }

      &-next {
        right: 0;

        &:hover,
        &:active {
          transform: scale(1.1);
        }
      }
    }

    &__item {
      position: relative;
      flex-grow: 1;
      align-items: center;
      margin: 0 0.5em;
      overflow: hidden;
      border-radius: 0.5em;
      justify-items: center;

      &-photo {
        height: 6em;

        @include tablet {
          height: 4.5em;
        }
        @include mobile {
          height: 4em;
        }
      }

      &-ext,
      &-filename,
      &-remove {
        position: absolute;
        padding: 3px;
        overflow: hidden;
        background-color: use-color('dark');
        color: use-color('green');
        font-size: 0.65em;
        font-weight: 600;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: center;
        transition: all 0.2s;
      }

      &-ext {
        top: 0;
        right: 0;
      }

      &-filename {
        top: 0;
        left: 0;
        width: 100%;
        padding-right: 2em;
      }

      &-remove {
        bottom: 0;
        padding: 3px;
        width: 2.5em;
        border-radius: 50%;
        background-color: use-color('green');

        &:hover,
        &:active {
          width: 100%;
          border-radius: 0;
        }

        > img {
          height: 1.5em;

          @include mobile {
            height: 1.25em;
          }
        }
      }
    }
  }

  &__documents {
    &__layout {
      flex-basis: 60%;
      align-items: center;
      justify-items: center;
    }

    &__wrapper {
      position: relative;
      align-items: center;
      padding: 0 1em;
      overflow-y: auto;
      flex-grow: 1;
      max-height: 8em;
    }

    &__item {
      position: relative;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      &:not(:last-child) {
        border-bottom: dashed 1px use-color(gray-light);
      }

      &-ext,
      &-link,
      &-remove {
        padding: 0.5em;
        overflow: hidden;
        border-radius: 5px;
        color: use-color(dark);
        font-size: 0.75em;
        text-align: right;
        text-overflow: ellipsis;
        transition: all 0.3s;
      }

      &-ext {
        background-color: use-color('gray');
        color: use-color('white');
        font-weight: 600;
      }

      &-link {
        flex-grow: 0.9;
        color: use-color(dark);
        font-size: 1em;

        &:hover,
        &:active {
          background-color: use-color(light-dim);
        }
      }

      &-remove {
        border-radius: 50%;
        font-size: 0.75em;

        > img {
          height: 1em;
        }
      }
    }
  }
}
