@import '../../styles/variables.scss';

.demo-bar {
  &__layout {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 50px;
    padding: 0 45px 0 75px;
    background-color: use-color('red');
    color: use-color('white');
  }

  &__text {
    font-size: 24px;
  }

  &__info {
    display: flex;
    align-items: center;
  }

  &__days {
    font-size: 18px;

    & > span {
      font-weight: 900;
    }
  }

  &__memory-layout {
    margin-left: 20px;
  }

  &__progress-bar {
    position: relative;
    display: inline-block;
    width: 260px;
    height: 20px;
    background: #ced2e0;
    border-radius: 4px;
  }

  &__serifs {
    position: absolute;
    top: 22px;
    display: flex;
    justify-content: space-between;
    width: 260px;
  }

  &__serif {
    width: 1px;
    height: 6px;
    background-color: use-color('white');

    &:nth-child(even) {
      height: 3px;
    }
  }

  &__memory {
    padding-left: 2px;
    vertical-align: bottom;
    font-size: 14px;
    line-height: 20px;
  }

  &__memory-taken {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 95px;
    height: 20px;
    background: use-color('gray');
    border-radius: 4px 0 0 4px;
    font-size: 12px;
  }

  &__button {
    width: 205px;
    height: 35px;
    margin-left: 60px;
    background: linear-gradient(
      use-color('dark-green'),
      use-color('dark-green')
    );
    border-radius: 5px;
    text-transform: uppercase;
    font-size: 18px;

    & > span {
      display: inline-block;
      margin-left: 5px;
      font-weight: 900;
    }
  }
}
